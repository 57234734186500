import React, { useContext, useEffect } from "react";
import { arrayMoveImmutable } from "array-move";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SortableElement, SortableContainer } from "react-sortable-hoc";

import { EditorContext, WidgetContext } from "contexts/editor";
import Icon from "components/Icon";

import UseItemActions from "components/useItemActions";
import UseLoading from "components/Table/useLoading";
import Widget from "components/Section/Design/Widgets/Widget";
import Toolbar from "components/Section/Design/Widgets/WidgetToolbars/CommonOptions/Toolbar";
import Settings from "components/Section/Design/Widgets/WidgetToolbars/CommonOptions/Settings";
import Input from "components/Section/Design/Widgets/WidgetToolbars/CommonOptions/Input";
import Select from "components/Section/Design/Widgets/WidgetToolbars/CommonOptions/Select";

import { setExecuteQuery } from "actions/sectionActions";
import { changeGlobalAction } from "actions/globalActions";
import { openModal, setModalContent } from "actions/uiActions";

import { capitalizeFirst } from "hooks/Utils/Utils";
import { Session } from "hooks/Utils/Session";
import {
    getOptionsFontList,
    getFontNameOptionSelected,
    getTextSizeSelected,
    getFontSizes,
} from "hooks/Utils/Design/FontUtils";
import { useMSQuery } from "hooks/GraphqlCalls/useMSQuery";
import WidgetItemNew from "hooks/GraphqlCalls/MediaLibrary/modals/WidgetItemNew";
import { getSectionName, getScreens as GET_SCREENS } from "hooks/Utils/Design/EditorUtils";

const SliderToolbar = () => {
    const { deviceType, theme, editorSize } = useContext(EditorContext);
    const { id: widgetID, type } = useContext(WidgetContext);
    const { lang } = useParams();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { fonts } = useSelector((state) => state.ui);
    const { gridItems, cScreenData } = useSelector((state) => state.design);
    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const style = Widget.ParseStyle(widget?.widgetStyle, deviceType);
    const config = Widget.ParseData(widget ? widget.data : null, deviceType);
    const sectionName = `toolbar`;
    const selectedMode = config?.mode || "simple";
    const selectedAdvance = config?.automatic ? "automatic" : "manual";
    const items = config?.items || [];

    const designId = cScreenData?.data?.designID;

    const [getScreens, { data: screensData, loading: loadingScreens }] = useMSQuery(GET_SCREENS(designId));

    const getContentName = (id) => {
        let name;
        const screensResult = screensData?.contentTree?.screens;
        const scr = screensResult?.dir?.contents?.filter((screen) => Number(screen.id) === Number(id));
        if (scr?.length > 0) {
            name = scr[0].name;
        } else {
            const subscr = screensResult?.dir?.subDirs?.filter((sub) => Number(sub.id) === Number(id));
            if (subscr?.length > 0) {
                name = subscr[0].name;
            }
        }
        return name;
    };

    const getSubtitle = (item) => {
        if (item?.actions?.length > 0) {
            const destType = item.actions[0]?.type;
            const destValue = item.actions[0]?.value;
            let destName;
            switch (destType) {
                case "content":
                    destName = getContentName(destValue);
                    return destName ? capitalizeFirst(t("screen")) + " - " + destName : "";
                case "section":
                    if (destValue === "landing") {
                        return t("landing");
                    }
                    return t("predefined section") + " - " + getSectionName(destValue, t);
                case "library":
                    return t("media file");
                case "tvchannel":
                    return t("TV channel");
                case "url":
                    return t("url") + " - " + destValue;
                default:
                    return destType;
            }
        }
        return "";
    };

    const setVisibilityItem = (id) => {
        let newValue = [...widget?.data?.items].map((item) =>
            item?.id === id
                ? {
                      ...item,
                      visibility: true,
                  }
                : item
        );
        dispatch(setExecuteQuery(Widget.UpdateData({ widget, property: "items", value: newValue })));
    };
    const getFromDAS = (libraryRef, languageRef) => {
        if (libraryRef) {
            let path = libraryRef;
            if (languageRef) {
                path += "?lang=" + languageRef;
            }
            return Session.getDasUrl(path);
        }
        return null;
    };

    useEffect(() => {
        getScreens();
    }, []);

    const updateConfig = (property, value) => {
        dispatch(setExecuteQuery(Widget.UpdateData({ widget, property, value })));
    };

    const updateMode = (value) => (selectedMode !== value ? updateConfig("mode", value) : null);
    const updateAdvance = (value) =>
        selectedAdvance !== value ? updateConfig("automatic", value === "automatic") : null;

    const SortableItem = SortableElement(({ item, i }) => {
        return (
            <li key={item.id} className=" relative mt-4 flex h-auto w-full cursor-move list-none items-center bg-white">
                <span
                    style={{ fontSize: "1rem" }}
                    className=" icon icon-drag-and-drop w-1/12 pr-3 text-gray-800 "
                ></span>

                <div
                    className="border rounded relative mr-2 flex items-center justify-center border-gray-200 "
                    style={{
                        width: "3.575rem",
                        height: "2.5rem",
                        color: `${item.visibility === true ? "" : "#576575"}`,
                        opacity: item.visibility === true ? 1 : 0.5,
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: item.visibility === true ? "white" : "rgba(87, 101, 117 , 0.5)",
                        backgroundImage: `url("${
                            item.libraryRef ? getFromDAS(item.libraryRef, lang) : item.externalUrl
                        }")`,
                    }}
                ></div>

                <div className=" truncate flex w-full flex-col items-start justify-center ">
                    <span
                        className={` ${item.visibility === true ? "text-gray-900" : " text-gray-500"} truncate w-full`}
                    >
                        {item?.name || t("New item")}
                    </span>
                    <span
                        className={` ${item.visibility === true ? "text-gray-700" : " text-gray-500"} truncate text-sm`}
                    >
                        {getSubtitle(item)}
                    </span>
                </div>
                <div className="w-1/12">
                    {item.visibility === true ? (
                        <UseItemActions
                            dataType="widget-slider"
                            containerAdjust="w-48"
                            executeSectionQuery={true}
                            id={item.id}
                            actions={[
                                {
                                    name: t("edit-item"),
                                    action: "edit-item-widget-item",
                                    modal_title: item?.name || "New item",
                                    modal_btn_name: "save",
                                    modal_twoColumns: true,
                                    modal_bodyAdjust: "w-full",
                                    widgetID,
                                    t,
                                    data: {
                                        itemName: item.name,
                                        itemText: item.text,
                                        resourceValue: {
                                            externalUrl: item.externalUrl,
                                            libraryRef: item.libraryRef,
                                        },
                                        itemActions: item.actions,
                                        itemId: item.id,
                                    },
                                    lang,
                                },
                                {
                                    name: t("duplicate"),
                                    mutation: "duplicate-item-widget-item",
                                    executeSectionQuery: true,
                                    noModal: true,
                                    mutationParams: {
                                        itemId: item?.id,
                                    },
                                    hideGlobalLoading: true,
                                },
                                {
                                    name: "hide-word",
                                    mutation: "hide-visibility-item-widget-item",
                                    executeSectionQuery: true,
                                    noModal: true,
                                    mutationParams: {
                                        itemId: item?.id,
                                    },
                                    hideGlobalLoading: true,
                                },
                                {
                                    name: t("delete"),
                                    action: "delete-item-widget-item",
                                    executeSectionQuery: true,
                                    modal_title: "delete",
                                    modal_value: item?.name || t("New item"),
                                    data: {
                                        itemId: item?.id,
                                    },
                                    hideGlobalLoading: true,
                                },
                            ]}
                        />
                    ) : (
                        <div
                            id={`action-set-visible-${item.id}`}
                            onClick={() => setVisibilityItem(item.id)}
                            className="icon icon-eye-blocked cursor-pointer text-gray-500 "
                            style={{ fontSize: "1rem" }}
                        ></div>
                    )}
                </div>
            </li>
        );
    });

    const SortableList = SortableContainer(({ items }) => {
        return (
            <ul
                className="grid grid-cols-1 gap-2"
                style={{ paddingBottom: items && items.length >= 1 ? "4.5rem" : "" }}
            >
                {items && items.length > 0
                    ? items.map((item, index) => (
                          <SortableItem key={`${item.id}`} index={index} sortIndex={index} item={item} i={index} />
                      ))
                    : null}
            </ul>
        );
    });

    const openModalAddItem = () => {
        const modalCont = WidgetItemNew(t, widgetID, { data: null }, lang);
        setTimeout(() => {
            dispatch(
                changeGlobalAction({
                    actionName: "create-item-widget-item",
                })
            );
        }, 1000);
        dispatch(setModalContent(modalCont));
        dispatch(openModal());
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newItems = arrayMoveImmutable(items, oldIndex, newIndex).map((item, index) => {
            return {
                ...item,
                position: index,
            };
        });
        dispatch(
            setExecuteQuery({
                action: "sort-items-widget-item",
                params: {
                    itemsSorted: newItems,
                },
            })
        );
    };

    return (
        <>
            <Toolbar type="navigation">
                <Settings>
                    <Settings.Group title={t("Manage items")}>
                        {loadingScreens ? (
                            <div className=" flex h-full w-full items-center justify-center">
                                <UseLoading adjust="contents" />
                            </div>
                        ) : (
                            <>
                                <div className="pb-4">{t("Add, order and edit the items")}</div>
                                <SortableList items={items} onSortEnd={onSortEnd} axis={"y"} distance={1} />
                            </>
                        )}
                    </Settings.Group>
                </Settings>
                <div className=" border-t sticky bottom-0 flex w-full flex-col border-gray-300 bg-white py-4 ">
                    <div
                        id="action-add-item"
                        className=" text-zafiro-600 flex cursor-pointer items-center justify-center font-bold"
                        onClick={openModalAddItem}
                    >
                        <span className="icon icon-add pr-2 " style={{ fontSize: "1rem", fontWeight: "bold" }}></span>
                        {t("Add item")}
                    </div>
                </div>
            </Toolbar>
            <Toolbar type="settings">
                <Settings>
                    <Settings.Group title={t("Text settings")}>
                        <div className="pb-4">{t("Select the text position")}</div>
                        <Input.Alignment inline={true} id="vAlignment" icons="alt" type="vertical" />
                    </Settings.Group>
                    <Settings.Group title={t("Text options")}>
                        <div className="flex justify-between">
                            {theme && (
                                <Select
                                    title={t("styletype")}
                                    id="fontStyle"
                                    adjust="w-full mr-2"
                                    data={{
                                        selected: style?.fontStyle || "paragraph",
                                        options: [
                                            { value: "header", text: t("heading") },
                                            { value: "paragraph", text: t("paragraph") },
                                        ],
                                    }}
                                />
                            )}
                            <Select
                                title={t("font")}
                                id="fontName"
                                adjust="w-full mr-2"
                                data={{
                                    selected: getFontNameOptionSelected(
                                        theme,
                                        fonts,
                                        deviceType,
                                        widget,
                                        Widget.ImplementNewLibrary(type)
                                    ),
                                    options: getOptionsFontList(fonts?.fontList),
                                }}
                            />
                            <Select
                                title={t("total size")}
                                id="size"
                                deviceSpecific={true}
                                adjust="w-full"
                                data={{
                                    selected: getTextSizeSelected(theme, deviceType, widget),
                                    options: getFontSizes(editorSize.width, deviceType),
                                }}
                            />
                        </div>
                        <Input.Check
                            icon="icon-bold"
                            dataType="style"
                            id="bold"
                            dafultValue={style?.bold === null ? theme?.paragraphStyle?.bold : style.bold}
                        />
                        <Input.Check
                            icon="icon-italic"
                            dataType="style"
                            id="italic"
                            dafultValue={style?.italic === null ? theme?.paragraphStyle?.italic : style.italic}
                        />
                        <div className="mt-4">
                            <div className="inline-block first-capital mb-2 mr-2 align-middle">{t("alignment")}</div>
                            <Input.Alignment inline={true} id="alignment" />
                        </div>
                    </Settings.Group>
                    <Settings.Group title={t("Items view")}>
                        <Settings.Radio
                            radioID={`${type}_radio_${widgetID}`}
                            id={`${sectionName}-${type}`}
                            title={t("Simple")}
                            value={"simple"}
                            selected={selectedMode}
                            action={updateMode}
                        />
                        <Settings.Radio
                            radioID={`${type}_radio_${widgetID}`}
                            id={`${sectionName}-${type}`}
                            title={t("Reel")}
                            value={"reel"}
                            selected={selectedMode}
                            action={updateMode}
                        />
                        {selectedMode === "reel" ? (
                            <div className="ml-8">
                                <strong>{t("Items")}</strong>
                                <div className="my-2">
                                    <Input.Number
                                        id="reelSize"
                                        deviceSpecific={true}
                                        dataType="data"
                                        title={t("size")}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </Settings.Group>
                    <Settings.Group title={t("Advance control")}>
                        <Settings.Radio
                            radioID={`${type}_radio_${widgetID}`}
                            id={`${sectionName}-${type}`}
                            title={t("manual")}
                            value={"manual"}
                            selected={selectedAdvance}
                            action={updateAdvance}
                        />
                        <Settings.Radio
                            radioID={`${type}_radio_${widgetID}`}
                            id={`${sectionName}-${type}`}
                            title={
                                <div className="flex items-center space-x-2">
                                    <div>{t("Manual and automatic")}</div>
                                    <Icon
                                        type="info"
                                        tooltip={t("The images will transition automatically")}
                                        size="2xl"
                                    />
                                </div>
                            }
                            value={"automatic"}
                            selected={selectedAdvance}
                            action={updateAdvance}
                        />
                        {selectedAdvance === "automatic" ? (
                            <div className="ml-8">
                                <div className="font-bold">{t("Automatic mode")}</div>
                                <div className="my-2">
                                    <Input.Number
                                        id="interval"
                                        dataType="data"
                                        title={t("image duration")}
                                        titleWidth="w-9/12"
                                        unit={t("seconds-short")}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </Settings.Group>
                </Settings>
            </Toolbar>
            <Toolbar type="style">
                <Settings.Group title={t("Item colors")}>
                    <Input.Color id={"bgColor"} title={t("Overlay color")} />
                    <Input.Color id={"fgColor"} title={t("text")} />
                    <Input.Color id={"items.bullets.fgColor"} title={t("Bullet points")} />
                </Settings.Group>
                <Settings.Border t={t} title="item-corners" onlyRadius={true} deviceSpecific={false} />
            </Toolbar>
            <Toolbar type="delete" />
        </>
    );
};

export default SliderToolbar;
