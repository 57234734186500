import React, { useEffect, useState } from "react";
import { TickerTapesContent } from "./TickerTapesContent";
import UseSectionHeader from "components/useSectionHeader";
import Switch from "components/Switch";
import { useListTickerTapes, useSetAvailabilityTickerTape } from "../graphql/useTickerTapes";
import AddFiltersCard from "../AddFiltersCard";
import ScheduleCard from "../ScheduleCard";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TICKER_TAPE_TYPE } from "../constants";
import { UseModalChangeAvailability } from "../modals/useModalChangeAvailability";

const TickerTapeDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { getTickerTapes, tickerTapes, loading } = useListTickerTapes();
    const { open: openModalChangeAvailabity, close: closeModalChangeAvailability } = UseModalChangeAvailability();
    const [isAvailable, setIsAvailable] = useState(false);

    const getTickerTapeData = () => {
        getTickerTapes({
            variables: {
                id,
            },
        });
    };

    useEffect(() => {
        getTickerTapeData();
    }, []);

    const { setAsAvailable, setAsUnavailable } = useSetAvailabilityTickerTape({
        onCompleted: () => {
            getTickerTapeData();
            closeModalChangeAvailability();
        },
    });

    const couldBeAvailable = false;
    const name = tickerTapes?.[0]?.name;

    return (
        <div>
            <div className=" flex justify-between items-end">
                <UseSectionHeader
                    title={tickerTapes?.[0]?.name}
                    buttonColor="btn-blue"
                    action={"function"}
                    value={"demo"}
                    navToSection={"/design/advertising/tickertapes"}
                    breadcrumbsLimit={3}
                />
                <div className="mb-8 flex gap-4">
                    <Switch
                        onChange={(val) => {
                            openModalChangeAvailabity({
                                name,
                                available: isAvailable,
                                type: TICKER_TAPE_TYPE,
                                id,
                                changeAvailability: isAvailable ? setAsUnavailable : setAsAvailable,
                                onCancel: () => {
                                    setIsAvailable(tickerTapes?.[0]?.available);
                                },
                            });
                            setIsAvailable(val.checked);
                        }}
                        tooltip={
                            !couldBeAvailable && !isAvailable
                                ? t("the-ticker-tape-will-be-able-to-set-as-available")
                                : null
                        }
                        disabled={!isAvailable && !couldBeAvailable}
                        checked={isAvailable}
                        label={t("available")}
                        className={"flex flex-row-reverse gap-2 font-bold"}
                        value={isAvailable}
                    ></Switch>
                </div>
            </div>
            <div className=" grid grid-cols-2 gap-4 gap-y-4">
                <ScheduleCard
                    data={tickerTapes}
                    loading={loading}
                    type={"signage"}
                    title={"schedule-ticker-tape"}
                    tooltip={"the-default-sequence-cannot-be-programmed"}
                    subtitle={"set-up-the-ticker-tape-schedule-to-be-displayed-in-the-tv-interface"}
                    display={"ticker-tape-display"}
                ></ScheduleCard>
                <AddFiltersCard subtitle={"set-up-the-filters-ticker-tape"}></AddFiltersCard>
                <TickerTapesContent
                    getData={getTickerTapeData}
                    translations={tickerTapes?.[0]?.content}
                    loading={loading}
                ></TickerTapesContent>
            </div>
        </div>
    );
};

export default TickerTapeDetails;
