import React from "react";
export const SIGNAGE_CONTENT_TYPES = ["ASSET", "VIDEO", "SCREEN"];
export const GROUP_CZ_TYPE = "roomGroupsTpCommonZone";
export const CZ_TYPE = "roomsTpCommonZone";
export const TV_LOCATION_TYPE = "roomTvsTpCommonZone";
export const SIGNAGE_TYPE = "sequence";
export const TICKER_TAPE_TYPE = "ticker-tape";
export const FILTER_VALUES = {
    AVAILABLE: "available",
    UNAVAILABLE: "unavailable",
    ONLY_EXPIRED: "onlyExpired",
    ONLY_ON_GOING: "onlyOnGoing",
};
export const INITIAL_BATCH_ACTIONS = ({ t }) => {
    return {
        duplicate: { label: t("duplicate"), value: "duplicate", disabled: false, id: "duplicate-batch-id" },
        available: {
            label: t("set-as-available"),
            value: "set-as-available",
            disabled: false,
            id: "set-as-available-batch-id",
        },
        unavailable: {
            label: t("set-as-unavailable"),
            value: "set-as-unavailable",
            disabled: false,
            id: "set-as-unavailable-batch-id",
        },
        delete: { label: t("delete"), value: "delete", disabled: false, id: "delete-batch-id" },
    };
};
export const HEADERS = ({ t }) => {
    return {
        name: { title: t("name"), sortable: true },
        schedule_end: { title: t("scheduled-date"), sortable: true },
        filters_num: { title: t("filters"), sortable: true },
        available: { title: t("status"), sortable: true },
    };
};
export const COLUMNS_SIGNAGE = ["name", "schedule_end", "filters_num", "available", "default"];
export const COLUMNS_TICKER_TAPES = ["name", "schedule_end", "filters_num", "available"];
export const stateFilterOptions = ({ t }) => {
    return [
        {
            id: "color-filter-available-ongoing-option-id",
            value: FILTER_VALUES.ONLY_ON_GOING,
            label: (
                <span>
                    {t("available")}
                    <span className="ml-1 text-gray-500">({t("on-going")})</span>
                </span>
            ),
        },
        {
            id: "color-filter-available-option-id",
            value: FILTER_VALUES.AVAILABLE,
            label: t("available"),
        },
        {
            id: "color-filter-available-expired-option-id",
            value: FILTER_VALUES.ONLY_EXPIRED,
            label: (
                <span>
                    {t("available")}
                    <span className="ml-1 text-gray-500">({t("expired")})</span>
                </span>
            ),
        },
        {
            id: "color-filter-unavailable-option-id",
            value: FILTER_VALUES.UNAVAILABLE,
            label: t("unavailable"),
        },
    ];
};
