import { gql } from "apollo-boost";

export const ADD_TICKER_TAPE = gql`
    mutation createTickerTape($name: String!) {
        createTickerTape(name: $name) {
            error
            id
            ok
        }
    }
`;

export const DELETE_TICKER_TAPES = gql`
    mutation deleteTickerTapes($ids: [Int64]!) {
        deleteTickerTapes(ids: $ids) {
            error
            id
            ok
        }
    }
`;

export const UPDATE_TICKER_TAPE = gql`
    mutation updateTickerTape($id: Int64!, $name: String, $content: [TranslateString]) {
        updateTickerTape(id: $id, name: $name, contentInput: $content) {
            error
            id
            ok
        }
    }
`;

export const SET_TICKER_TAPE_AS_AVAILABLE = gql`
    mutation setTickerTapesAsAvailable($ids: [Int64]) {
        setTickerTapesAsAvailable(ids: $ids) {
            error
            id
            ok
        }
    }
`;

export const SET_TICKER_TAPE_AS_UNAVAILABLE = gql`
    mutation setTickerTapesAsUnavailable($ids: [Int64]!) {
        setTickerTapesAsUnavailable(ids: $ids) {
            error
            id
            ok
        }
    }
`;

export const DUPLICATE_TICKER_TAPE = gql`
    mutation duplicateTickerTape($ids: [Int64]!) {
        duplicateTickerTapes(ids: $ids) {
            error
            id
            ok
        }
    }
`;
