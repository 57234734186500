import { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import {
    MUTATION_CREATE_GROUP,
    DELETE_PROPERTY_GROUP,
    DUPLICATE_PROPERTY_GROUP,
    UPDATE_PROPERTY_GROUP,
    GET_CORPORATE_DESIGNS,
    GET_DESIGNS_WITH_LINKED_SIGNAGES,
} from "./mutationsQueries";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { checkHasLinkedSignages } from "components/Section/Design/Advertising/utils";

export const useGetHasLinkedSignages = ({ refs }) => {
    const [designIds, setDesignIds] = useState([]);
    const [hasLinkedSignagesState, setHasLinkedSignagesState] = useState(false);

    const [getDesignByProperties, { data: dataDesignByProperties, loading: loadingDesigns }] = useLazyQuery(
        GET_CORPORATE_DESIGNS,
        { fetchPolicy: "network-only" }
    );

    const [getContentTree, { data: dataContentTree, loading: loadingContentTree }] = useLazyQuery(
        GET_DESIGNS_WITH_LINKED_SIGNAGES(designIds),
        { fetchPolicy: "network-only" }
    );

    const loading = loadingDesigns || loadingContentTree;

    useEffect(() => {
        getDesignByProperties();
    }, []);

    useEffect(() => {
        const results = dataDesignByProperties?.designs?.results ?? [];
        const filteredIds = results
            .filter(
                (design) => design?.visibleAllProject?.type === "BRAND" && refs?.includes(design.visibleAllProject.ref)
            )
            .map((design) => design.id);

        if (filteredIds.length > 0) {
            setDesignIds(filteredIds);
        }
    }, [dataDesignByProperties]);

    useEffect(() => {
        if (designIds.length > 0) {
            getContentTree();
        }
    }, [designIds]);

    useEffect(() => {
        if (dataContentTree) {
            setHasLinkedSignagesState(checkHasLinkedSignages(dataContentTree));
        }
    }, [dataContentTree]);

    // False until we have the data of what design is applied to the properties
    return { hasLinkedSignages: false, loading };
};

const useMutationWithHandlers = (mutation, { close, refetch }) => {
    const { t } = useTranslation();

    const [mutateFunction, { loading }] = useMutation(mutation, {
        onCompleted: () => {
            toast.success(t("operation-successful"));
            if (typeof refetch === "function") {
                refetch();
            }
            if (typeof close === "function") {
                close();
            }
        },
        onError: (error) => {
            const errorMessage = error?.graphQLErrors?.[0]?.message || error.message || "An unknown error occurred";
            toast.error(errorMessage);
            if (typeof refetch === "function") {
                refetch();
            }
            if (typeof close === "function") {
                close();
            }
        },
    });

    return { mutateFunction, loading };
};

export const useCreateGroupProperty = (params) => {
    const { mutateFunction: createGroupProperty, loading } = useMutationWithHandlers(MUTATION_CREATE_GROUP, params);
    return { createGroupProperty, loadingCreateGroupProperty: loading };
};

export const useDeletePropertyGroup = (params) => {
    const { mutateFunction: deletePropertyGroup, loading } = useMutationWithHandlers(DELETE_PROPERTY_GROUP, params);
    return { deletePropertyGroup, loadingDeletePropertyGroup: loading };
};

export const useDuplicatePropertyGroup = (params) => {
    const { mutateFunction: duplicatePropertyGroup, loading } = useMutationWithHandlers(
        DUPLICATE_PROPERTY_GROUP,
        params
    );
    return { duplicatePropertyGroup, loadingDuplicatePropertyGroup: loading };
};

export const useUpdatePropertyGroup = (params) => {
    const { mutateFunction: updatePropertyGroup, loading } = useMutationWithHandlers(UPDATE_PROPERTY_GROUP, params);
    return { updatePropertyGroup, loadingUpdatePropertyGroup: loading };
};
