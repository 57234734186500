import React from "react";
import Button from "components/Button";
import Icon from "components/Icon";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const AddFiltersCard = ({ data, loading, getData, openModal, subtitle }) => {
    const { t } = useTranslation();
    const { isDefault, filtersNum, id, destinationInfo } = data?.[0] || {};
    const numberOfFilters = filtersNum || 0;
    return (
        <div className=" w-full flex flex-col gap-4 bg-white p-4 rounded min-h-full">
            <div className=" flex justify-between">
                <p className=" font-bold text-2xl">{t("add-filters")}</p>
                <Button
                    id={"edit-sequence-filters"}
                    onClick={() => {
                        openModal({ id, getData, filters: destinationInfo });
                    }}
                    disabled={loading || isDefault}
                >
                    <Icon
                        tooltip={isDefault ? t("the-default-sequence-cannot-be-filtered") : ""}
                        className={classNames({ "text-gray-700": isDefault })}
                        size={1.2}
                        type={"edit"}
                    ></Icon>
                </Button>
            </div>

            <p className={classNames({ "text-gray-700": isDefault, "text-gray-800": !isDefault })}>{t(subtitle)}</p>

            {numberOfFilters > 0 ? (
                <p className=" text-green-500">{t("filters-x", { count: numberOfFilters })}</p>
            ) : (
                <p className=" text-gray-700">{t("no-filters-yet")}</p>
            )}
        </div>
    );
};

export default AddFiltersCard;
