import { Session } from "hooks/Utils/Session";
import { tmpGetChain } from "hooks/Utils/Utils";
import { CORPORATE_REF } from "constants/permissions";

function NewUser(user, t) {
    const chain = tmpGetChain();

    const userManagerRole = Session.getUser()?.role;
    const isCorporate = Session.isCorporate();
    const hasCorporate = Session.hasCorporate();
    const hasChainModule = Session.hasChainModule();
    const managerUserData = Session.getSessionProp("cUser") ? JSON.parse(Session.getSessionProp("cUser")) : null;
    const brands = managerUserData?.brands || [];
    const name = user?.name || null;
    const email = user?.email || null;
    const userId = user?.id || null;
    const selectedAccesses = user?.roles || null;
    const selectedProperties = user?.properties || null;
    const selectedBrands = user?.brands || null;
    const assignedAll = !!user?.assignedAll;
    const corporateUser = !!user?.corporateUser;
    const projectsData = [];
    const corporateUserSwitch = [];

    if (chain?.name) {
        if (userManagerRole || assignedAll || managerUserData?.assignedAll) {
            let disabled = true;
            if (userManagerRole) {
                disabled = false;
            } else if (managerUserData?.assignedAll) {
                disabled = false;
            }
            projectsData.push({
                id: `all`,
                name: chain.name,
                selected: assignedAll,
                disabled: disabled,
                category: "chain",
                selectUnique: true,
                selectedLabelColor: "bg-purple-600 text-white",
            });
        }
    }

    if (brands) {
        // eslint-disable-next-line
        brands.map((brand) => {
            let itemPreSelected = null;
            if (brand.ref && brand.ref !== "undefined") {
                if (selectedBrands?.length) {
                    itemPreSelected = selectedBrands.filter((b) => b.ref === brand.ref);
                }
                projectsData.push({
                    id: `${brand.ref}`,
                    name: brand.name,
                    selected: !assignedAll && itemPreSelected !== null && itemPreSelected.length > 0 ? true : false,
                    category: "groups",
                    disabled: assignedAll,
                    selectedLabelColor: "bg-orange-100 text-white",
                });
            }
            return brand;
        });
    }

    if (selectedBrands != null && selectedBrands.length > 0) {
        // eslint-disable-next-line
        selectedBrands.map((selectedBrand) => {
            let addProperty = true;
            if (projectsData !== null && projectsData.length > 0) {
                let itemPreSelected = projectsData.filter((p) => p.id === `${selectedBrand.ref}`);
                if (itemPreSelected !== null && itemPreSelected.length > 0) {
                    addProperty = false;
                }
            }
            if (addProperty) {
                projectsData.push({
                    id: `${selectedBrand.ref}`,
                    name: selectedBrand.name,
                    selected: !assignedAll,
                    category: "groups",
                    selectedLabelColor: "bg-orange-100 text-white",
                    disabled: assignedAll,
                });
            }
        });
    }

    if (Session.getProjects() !== null && Session.getProjects() !== undefined) {
        // eslint-disable-next-line
        Session.getProjects().map((project) => {
            let itemPreSelected = null;
            if (project.ref && project.ref !== "undefined" && project.ref !== CORPORATE_REF) {
                if (selectedProperties !== null && selectedProperties.length > 0) {
                    itemPreSelected = selectedProperties.filter((p) => p.ref === project.ref);
                }
                projectsData.push({
                    id: `${project.ref}`,
                    name: project?.name,
                    display: {
                        type: "ProjectName",
                        props: { hasChainModule: project?.hasChainModule, name: project?.name },
                    },
                    selected: !assignedAll && itemPreSelected !== null && itemPreSelected.length > 0 ? true : false,
                    category: "properties",
                    disabled: assignedAll,
                    selectedLabelColor: "bg-teal-500 text-white",
                });
            }
        });
    }
    if (!assignedAll && selectedProperties != null && selectedProperties.length > 0) {
        // eslint-disable-next-line
        selectedProperties.map((selectedProperty) => {
            let addProperty = true;
            if (projectsData !== null && projectsData.length > 0) {
                let itemPreSelected = projectsData.filter((p) => p.id === `${selectedProperty.ref}`);
                if (itemPreSelected !== null && itemPreSelected.length > 0) {
                    addProperty = false;
                }
            }
            if (addProperty) {
                projectsData.push({
                    id: `${selectedProperty.ref}`,
                    name: selectedProperty.name,
                    selected: !assignedAll,
                    category: "properties",
                    selectedLabelColor: "bg-teal-500 text-white",
                    disabled: true,
                });
            }
        });
    }

    if (hasCorporate) {
        corporateUserSwitch.push({
            type: "switch",
            checked: corporateUser,
            text: t("global-management-user"),
            name: "corporateUser",
            info: t("global-management-user-info"),
        });
    }

    const rolesQuery = `{
        data: roles (orderBy:{field:"name",criteria:"asc"}){
            info { count }
            results { id name type  }
          }          
        }
        `;

    return [
        {
            title: name ? [name, "-", "edit-user"] : "add-user",
            bodyAdjust: "w-4/12",
            id: userId,
            executeSectionQuery: true,
            inputs: [
                {
                    text: "fullname",
                    name: "user-name",
                    value: name,
                    type: "text",
                    focus: true,
                },
                {
                    text: "email",
                    name: "user-email",
                    value: email,
                    type: "text",
                    toLowerCase: true,
                    validationsQuery: {
                        query: `{
                            validationInfo: users(email:"%0"){
                              info{
                                count
                              }
                              results{
                                fullName,
                                email
                              }
                            }
                          }`,
                        validateOnKeyUp: false,
                        validateOnBlur: true,
                        validationType: "exist_data",
                        validationMessage: "user-email-exist-footenote",
                        validationAttribute: "results[0].email",
                        validationElementsEdit: [
                            { attr: "data-action", value: "add-manager-user", class: "hidden" },
                            { attr: "data-action", value: "edit-user", class: "hidden" },
                        ],
                        validationIgnoreValue: email,
                    },
                },
                {
                    text: t("Select the property or group of properties to be accessed") + ":",
                    name: "properties",
                    type: "selectMultipleWithSearch",
                    optionData: projectsData,
                    selectedIdsForOQ: selectedProperties,
                    translateOptions: true,
                    noSelect: true,
                    selectPlaceHolder: t("select-properties"),
                    labelsColors: "bg-teal-500 text-white",
                },
                ...corporateUserSwitch,
                {
                    text: t("Select the role that it gives permission to manage the different functionalities") + ":",
                    name: "roles",
                    type: "selectMultipleWithSearch",
                    optionsQuery: rolesQuery,
                    noSelect: true,
                    selectedIdsForOQ: selectedAccesses,
                    selectPlaceHolder: "select-roles",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "save",
                    style: "blue",
                    action: "add-manager-user",
                },
            ],
        },
    ];
}

export default NewUser;
