import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseListSignages, useAvailabilitySignage } from "./graphql/useSignages";
import { useParams } from "react-router-dom";
import ScheduleCard from "./ScheduleCard";
import UseSectionHeader from "components/useSectionHeader";
import { UseModalMakeDefaultSequence } from "./modals/signages/useModalMakeDefaultSequence";
import { UseModalChangeAvailability } from "./modals/useModalChangeAvailability";
import { UseModalAddFilters } from "./modals/useModalAddFilters";
import useScheduleSequence from "components/Section/MediaLibrary/modals/useScheduleSequence";
import Button from "components/Button";
import { useLoading } from "components/Loading";
import Switch from "components/Switch";
import SignageContent from "./SignageContent";
import AddFiltersCard from "./AddFiltersCard";
import { SIGNAGE_TYPE } from "./constants";

const SignageDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [isAvailable, setIsAvailable] = useState(false);
    const { open: openModalMakeDefault } = UseModalMakeDefaultSequence();
    const { open: openModalScheduleSequence } = useScheduleSequence();
    const { open: openModalChangeAvailabity, close: closeModalChangeAvailability } = UseModalChangeAvailability();
    const { open: openModalAddFilters } = UseModalAddFilters();
    const { setLoading } = useLoading();
    const { getSignages, loading, signages } = UseListSignages();

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    useEffect(() => {
        setIsAvailable(signages?.[0]?.available);
    }, [signages]);

    const getSignagesData = () => {
        getSignages({
            variables: {
                id,
            },
        });
    };

    useEffect(() => {
        const signage = signages?.[0];
        if ((!signage?.contents || signage?.filtersNum == 0) && signage?.available) {
            setAsUnavailable({ variables: { ids: [id] } });
        }
    }, [signages]);

    useEffect(() => {
        getSignagesData();
    }, []);

    const { setAsAvailable, setAsUnavailable } = useAvailabilitySignage({
        onCompleted: () => {
            getSignagesData();
            closeModalChangeAvailability();
        },
    });

    const isDefault = signages?.[0]?.isDefault;
    const name = signages?.[0]?.name;
    const contents = signages?.[0]?.contents;
    const scheduleDailyType = signages?.[0]?.scheduleDailyType;
    const roomGroupsTpCommonZone = signages?.[0]?.destinationInfo?.roomGroupsTpCommonZone || [];
    const roomTvsTpCommonZone = signages?.[0]?.destinationInfo?.roomTvsTpCommonZone || [];
    const roomsTpCommonZone = signages?.[0]?.destinationInfo?.roomsTpCommonZone || [];
    const numberOfFilters = roomGroupsTpCommonZone?.length + roomTvsTpCommonZone?.length + roomsTpCommonZone?.length;
    const couldBeAvailable = contents?.length > 0 && scheduleDailyType && numberOfFilters > 0;

    return (
        <div>
            <div className=" flex justify-between items-end">
                <UseSectionHeader
                    title={name}
                    buttonColor="btn-blue"
                    action={"function"}
                    value={"demo"}
                    navToSection={"/design/advertising/signage"}
                    breadcrumbsLimit={2}
                />
                {isDefault ? (
                    <p className=" align-bottom text-gray-400 mb-8">{t("default-sequence")}</p>
                ) : (
                    <div className="mb-8 flex gap-4">
                        <Button
                            id={"make-as-default"}
                            design={"link"}
                            onClick={() => {
                                openModalMakeDefault({
                                    id: signages?.[0]?.id,
                                    name: signages?.[0]?.name,
                                    getSignages: getSignagesData,
                                });
                            }}
                        >
                            {t("make-default")}
                        </Button>

                        <Switch
                            onChange={(val) => {
                                openModalChangeAvailabity({
                                    name,
                                    available: isAvailable,
                                    type: SIGNAGE_TYPE,
                                    id,
                                    changeAvailability: isAvailable ? setAsUnavailable : setAsAvailable,
                                    onCancel: () => {
                                        setIsAvailable(signages?.[0]?.available);
                                    },
                                });
                                setIsAvailable(val.checked);
                            }}
                            tooltip={
                                !couldBeAvailable && !isAvailable
                                    ? t("the-sequence-will-be-able-to-set-as-available")
                                    : null
                            }
                            disabled={!isAvailable && !couldBeAvailable}
                            checked={isAvailable}
                            label={t("available")}
                            className={"flex flex-row-reverse gap-2 font-bold"}
                            value={isAvailable}
                        ></Switch>
                    </div>
                )}
            </div>

            <div className=" flex w-full space-x-8 relative">
                <ScheduleCard
                    data={signages}
                    loading={loading}
                    openModal={openModalScheduleSequence}
                    type={"signage"}
                    title={"schedule-secuence"}
                    tooltip={"the-default-sequence-cannot-be-programmed"}
                    subtitle={"set-up-the-sequence-schedule-to-be-displayed-in-the-tv-interface"}
                    display={"sequence-display"}
                    getData={() => {
                        getSignagesData();
                    }}
                />
                <AddFiltersCard
                    data={signages}
                    loading={loading}
                    subtitle={"set-up-the-filters"}
                    openModal={openModalAddFilters}
                    getData={() => {
                        getSignagesData();
                    }}
                />
            </div>
            <div>
                <div>
                    <SignageContent
                        data={signages}
                        loading={loading}
                        getData={() => {
                            getSignagesData();
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default SignageDetails;
