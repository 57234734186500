import React from "react";
import { useParams } from "react-router-dom";

import Designs from "sections/design/designs";
import EditDesign from "sections/design/edit";

const List = () => {
    const { id } = useParams();

    if (!id || id === "list") {
        return <Designs />;
    }

    return <EditDesign id={id} />;
};

export default List;
