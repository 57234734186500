import { gql } from "apollo-boost";

import useGQLQuery from "hooks/useQuery";
import { useAuth } from "hooks/Session/auth";

const useBrands = ({ onSuccess, onError } = {}) => {
    const { allProjects } = useAuth();
    const query = gql`
        query brands($page: Int, $size: Int, $orderBy: OrderBy, $filter: BrandFilter, $ids: [Int64]) {
            brands(page: $page, size: $size, orderBy: $orderBy, filter: $filter, IDs: $ids) {
                info {
                    count
                }
                results {
                    id
                    ref
                    name
                    assignedAll
                    hasUsers
                    projects {
                        id
                        ref
                        name
                        hasChainModule
                        hasDefaultDesign
                    }
                }
            }
        }
    `;

    const q = useGQLQuery(query, {
        onSuccess: (response) => {
            if (onSuccess) {
                onSuccess(response?.brands?.results);
            }
        },
        onError,
    });
    return {
        ...q,
        count: q.data?.brands?.info?.count,
        data: q.data?.brands?.results?.length
            ? q.data.brands.results.map((b) => {
                  if (b?.projects?.length) {
                      b.projects = b.projects.map((p) => {
                          const project = allProjects?.find((pr) => pr.ref === p.ref);
                          if (project) {
                              p.disabled = project.disabled;
                              p.designID = project.designID;
                          }
                          return p;
                      });
                      b.disabled = b.projects?.some((p) => p.disabled);
                  }
                  return b;
              })
            : [],
        load: ({
            page,
            size,
            orderBy,
            filter = {
                refs: undefined, // [string]
                property: undefined, // [int]
                search: undefined, // string
            },
            ids,
        } = {}) =>
            q.load({
                page,
                size,
                orderBy,
                filter,
                ids,
            }),
    };
};

export default useBrands;
