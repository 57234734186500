import { gql } from "apollo-boost";

export const GET_TICKER_TAPES = gql`
    query listTicketTapes(
        $scheduleFrom: String
        $scheduleTo: String
        $search: String
        $orderBy: OrderBy
        $id: Int64
        $available: [Boolean]
        $onlyOnGoing: Boolean
        $onlyExpired: Boolean
        $currentTime: DateTime
    ) {
        tickerTapes(
            filter: {
                search: $search
                scheduleFrom: $scheduleFrom
                scheduleTo: $scheduleTo
                id: $id
                available: $available
                onlyOngoing: $onlyOnGoing
                onlyExpired: $onlyExpired
            }
            orderBy: $orderBy
            lang: "all"
            currentDateTime: $currentTime
        ) {
            results {
                id
                available
                name
                content
                contentTID
                destinationID
                destinationInfo {
                    rooms {
                        id
                        name
                    }
                    roomsTpCommonZone {
                        id
                        name
                    }
                }
                displayTextSize
                displayScrollSpeed
                displayStyles
                scheduleStart
                scheduleEnd
                scheduleDaysType
                scheduleDaysOfWeek
                scheduleObtrusiveMode
                scheduleFrequencyLimited
                scheduleFrequencyDisplay
                scheduleFrequencyRepetitions
                ongoing
                filtersNum
                timeRanges {
                    endTime
                    startTime
                }
            }
        }
    }
`;
