import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams, useNavigate as useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";

//Actions
import { openModal, setModalContent, showGlobalLoading } from "../../actions/uiActions";
import { setRefreshContentData, setExecuteQuery } from "../../actions/sectionActions";
import { setCurrentDevice, setDesignToolbarVisible, setEditingMenu } from "../../actions/designActions";
import { setActionName, setActionItemAffected, changeActionValues, prepareUpdates } from "../../actions/globalActions";

//Components
import UseItemActions from "../useItemActions";
import AddScreenFromTemplate from "../Section/Design/Modals/AddScreenFromTemplate";
import AddFolder from "../Section/Design/Modals/AddFolder";
import UseLoading from "../Table/useLoading";

//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import ReactTooltip from "components/ReactTooltip";
import { capitalizeFirst, normalizeToSearch } from "../../hooks/Utils/Utils";
import ErrorInfo from "components/ErrorInfo";

import { useAuth } from "hooks/Session/auth";
import { SCREEN, DEVICE } from "constants/editor";
import { CORPORATE_REF } from "constants/permissions";
import {
    CONTENT_CATEGORY,
    EDITOR_MODE,
    getScreenItemActions,
    getWelcomeItemActions,
    TRANSLATE_SCREEN_TYPE_TITLE,
    filterBySearch,
} from "../../hooks/Utils/Design/EditorUtils";
import _ from "lodash";

export default function ScreensSidebar({ landings, toolbarID, prefixId }) {
    //Params
    const { id, screenid, type, landingid } = useParams();
    const history = useHistory();
    const { t } = useTranslation();
    const location = useLocation();

    const { project } = useAuth();

    let prevScreenType = null;

    //States
    const [menuItems, setMenuItems] = useState(null);
    const [menuItemsFiltered, setMenuItemsFiltered] = useState(menuItems);
    const [activeCategory, setActiveCategory] = useState(null);
    const [screenMenuOver, setScreenMenuOver] = useState({});
    const [showLoading, setShowLoading] = useState(null);
    const [firstScreensLoad, setFirstScreenLoad] = useState(true);
    const [valSearch, setValSearch] = useState("");

    //Store data
    const { preparedUpdates } = useSelector((state) => state.action);
    const { refreshData } = useSelector((state) => state.sectionContent);
    const executeQuerySC = useSelector((state) => state.sectionContent.executeQuery);
    const { permissions } = useSelector((state) => state.ui);
    const { designToolbarVisible, cLang, editorMode } = useSelector((state) => state.design);
    const projectRef = project?.ref;

    const GET_SCREENS = gql`
    {
        contentTree (designID: ${id}){
            ${
                landings
                    ? `screens:landings {                
                categories{
                    category
                    types {
                        __typename ...on ltTypeWelcome {
                            type
                            welcomes {
                              id
                              isAssigned
                              isDefault
                              isEnabled
                              name
                              type
                              contentIDs
                            }
                        }
                        ...on ltTypeLanding {
                            type
                            landings {
                                id : contentID
                                isAssigned
                                isDefault
                                isEnabled
                                name
                                type
                                landingId: id
                            }
                        }
                        ... on ltTypePredefinedScreen{
                            type
                            predefinedScreens {
                              id
                              type
                              name
                              isPublished
                            }
                          }
                    }
                }
            }`
                    : `
            screens{
                dir{
                    contents{
                        id
                        isEnabled
                        isPublished
                        isLinked
                        name
                        type
                        linkedFrom
                        linkedSignages{
                                id
                                name
                        }
                    }
                    subDirs {
                        id
                        name
                        contents {
                            id
                            isEnabled
                            isPublished
                            isLinked
                            name
                            type
                            linkedFrom
                            linkedSignages{
                                id
                                name
                            }
                        }
                    }
                }
            }
            `
            }
        }
        projectLangs {

            results {
                isDefault
                languageRef
            }
        }

        distinctProjectLangs{
            results
        }
    }
    `;

    const [executeQuery, { data, error, loading, refetch }] = useLazyQuery(GET_SCREENS, {
        fetchPolicy: "network-only",
    });

    //Actions
    const dispatch = useDispatch();
    const products = permissions?.product;
    const tvEnabled = products?.tv;
    const welcomeEnabled = permissions?.guests?.welcomeScreens;
    const mobileEnabled = products?.mobile;
    const castEnabled = products?.cast;
    const wifiEnabled = products?.wifi;
    const hasCloudSales = products?.sales && !permissions?.legacy?.sales;

    //Listeners
    useEffect(() => {
        executeQuery();
        return () => {
            dispatch(setDesignToolbarVisible());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (designToolbarVisible) {
            if (preparedUpdates?.screensSidebar) {
                dispatch(prepareUpdates({ screensSidebar: false }));
                if (refetch) refetch();
            }
        }
        // eslint-disable-next-line
    }, [designToolbarVisible]);

    useEffect(() => {
        if (
            executeQuerySC &&
            (executeQuerySC.mutationName === "createContent" ||
                executeQuerySC.mutationName === "createDir" ||
                executeQuerySC.mutationName === "deleteDir")
        ) {
            if (typeof refetch === "function") {
                refetch();
            }
            dispatch(setExecuteQuery(null));
        }
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        setShowLoading(loading);
        // eslint-disable-next-line
    }, [loading]);

    useEffect(() => {
        if (refreshData) {
            refetch();
            dispatch(setRefreshContentData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        setShowLoading(false);
        dispatch(showGlobalLoading(false));
        if (data?.contentTree) {
            let menuItemsArray = [];
            // eslint-disable-next-line
            let first = null;
            let currentCat = null;
            let subDirs = null;
            const screens = ParseContentTree(data.contentTree, {
                cast: castEnabled,
                tv: tvEnabled,
                mobile: mobileEnabled,
                wifi: wifiEnabled,
                general: true,
            });
            //get default lang
            let lang = "en";
            if (
                projectRef === CORPORATE_REF &&
                data.distinctProjectLangs &&
                data.distinctProjectLangs.results &&
                data.distinctProjectLangs.results[0]
            ) {
                lang = data.distinctProjectLangs.results[0];
            } else if (data?.projectLangs?.results && data.projectLangs.results.length > 0) {
                const dLang = data.projectLangs.results.filter((cLang) => cLang.isDefault);
                if (dLang?.[0]) {
                    lang = dLang[0].languageRef;
                }
            }
            if (landings && screens) {
                screens.map((content) => {
                    if (!first) first = content.category;
                    let catScreens = [];
                    //GENERATE EACH SCREEN PATH TO REDIRECT
                    content.types.forEach((cType) => {
                        switch (cType.type) {
                            case SCREEN.TYPE.WELCOME:
                                cType.welcomes.map(
                                    (welcome) =>
                                        (welcome.path = generateScreenPath(
                                            cType.type,
                                            welcome.id,
                                            lang,
                                            welcome.contentIDs[0]
                                        ))
                                );
                                break;
                            case SCREEN.TYPE.SALES:
                            case SCREEN.TYPE.SALESMOBILE:
                                if (cType.predefinedScreens) {
                                    cType.predefinedScreens.map(
                                        (pdScreen) => (pdScreen.path = generateScreenPath(cType.type, pdScreen.id))
                                    );
                                }
                                break;
                            case SCREEN.TYPE.EXPRESSCHECKOUT:
                                break;
                            default:
                                cType.landings.map(
                                    (screen) =>
                                        (screen.path = generateScreenPath(
                                            cType.type,
                                            screen.id,
                                            lang,
                                            screen.landingId
                                        ))
                                );
                                break;
                        }
                    });
                    content.types = ParseScreenTypes(content.types, {
                        welcome: welcomeEnabled,
                        sales: hasCloudSales,
                    });
                    // eslint-disable-next-line
                    content.types.map((cType) => {
                        let screensTypesToAdd = null;
                        switch (cType.type) {
                            case SCREEN.TYPE.WELCOME:
                                screensTypesToAdd = cType.welcomes;
                                break;
                            case SCREEN.TYPE.SALES:
                            case SCREEN.TYPE.SALESMOBILE:
                                screensTypesToAdd = cType.predefinedScreens;
                                break;
                            case SCREEN.TYPE.EXPRESSCHECKOUT:
                                break;
                            default:
                                screensTypesToAdd = cType.landings;
                                break;
                        }
                        if (screensTypesToAdd) {
                            catScreens = catScreens.concat(screensTypesToAdd);
                        }
                    });
                    menuItemsArray.push({
                        id: content.category,
                        name: t("SCREEN-TYPE-" + content.category),
                        types: content.category === SCREEN.TYPE.TV ? orderTVScreenTypes(content.types) : content.types,
                        screens: SortContents(catScreens),
                        subDirs: subDirs,
                    });
                    if (content.types)
                        content.types.map((t) => {
                            if (type === t.type) currentCat = content.category;
                            return t;
                        });

                    return content;
                });
            } else {
                currentCat = CONTENT_CATEGORY.GENERAL;
                // eslint-disable-next-line
                screens?.subDirs?.map((subDir) => {
                    subDir.collapsed = false;
                    subDir.ref = `folder${subDir.id}`;
                    subDir.contents.map((screen) => (screen.path = generateScreenPath(currentCat, screen.id, lang)));
                });
                screens.contents.map((screen) => (screen.path = generateScreenPath(currentCat, screen.id, lang)));
                menuItemsArray.push({
                    id: CONTENT_CATEGORY.GENERAL,
                    name: t("SCREEN-TYPE-GENERAL"),
                    screens: SortContents(screens.contents),
                    subDirs: screens.subDirs,
                });
            }
            if (!activeCategory) {
                setActiveCategory(currentCat || first);
            }
            setMenuItems(addContinuousFinalIndexIncludingSubDirs(menuItemsArray));

            let forcePath = true;
            //Check if screen id is in results
            const idToFind = type === SCREEN.TYPE.WELCOME ? landingid : screenid;
            if (idToFind) {
                menuItemsArray.forEach((arrayItem) => {
                    if (findScreen(idToFind, arrayItem)) {
                        forcePath = false;
                    }
                });
            }

            if ((!idToFind || forcePath) && menuItemsArray && menuItemsArray.length > 0) {
                if (designToolbarVisible === toolbarID) {
                    const menu = menuItemsArray.filter((i) => {
                        const isLanding =
                            i.id === CONTENT_CATEGORY.TV ||
                            i.id === CONTENT_CATEGORY.MOBILE ||
                            i.id === CONTENT_CATEGORY.WIFI ||
                            i.id === CONTENT_CATEGORY.CAST;
                        return toolbarID === "landings" ? isLanding : !isLanding;
                    });

                    const firstScr = firstScreen(menu);
                    if (firstScr) {
                        if (
                            firstScr.type === "tv-home" ||
                            firstScr.type === "welcome-sequences" ||
                            firstScr.type === "SALES"
                        ) {
                            setActiveCategory("TV");
                        } else if (firstScr.type === "MOBILE" || firstScr.type === "SALESMOBILE") {
                            setActiveCategory("MOBILE");
                        } else if (firstScr.type === "CCINSTRUCTION" || firstScr.type === "CCPAIRINGSUCCESSFUL") {
                            setActiveCategory("CAST");
                        } else if (firstScr.type === "PORTAL") {
                            setActiveCategory("WIFI");
                        }
                        history(firstScr.path);
                    }
                }
            } else {
                if (firstScreensLoad) {
                    dispatch(setDesignToolbarVisible(toolbarID));
                    setFirstScreenLoad(false);
                }
            }

            setShowLoading(false);
        }
        // eslint-disable-next-line
    }, [data]);

    const generateScreenPath = (type, screenId, lang = cLang, landingId = null) => {
        let _path = "";
        switch (type) {
            case SCREEN.TYPE.TV:
            case SCREEN.TYPE.MOBILE:
            case SCREEN.TYPE.CCINSTRUCTION:
            case SCREEN.TYPE.CCPAIRINGSUCCESSFUL:
            case SCREEN.TYPE.PORTAL:
                _path = `/design/${id}/screen/${type}/${screenId}/${lang}/${landingId}`;
                break;
            case SCREEN.TYPE.WELCOME:
                _path = `/design/${id}/screen/${type}/${landingId}/${lang}/${screenId}`;
                break;
            case SCREEN.TYPE.SALES:
            case SCREEN.TYPE.SALESMOBILE:
            case SCREEN.TYPE.GENERAL:
            default:
                _path = `/design/${id}/screen/${type}/${screenId}/${lang}`;
                break;
        }
        return _path;
    };

    useEffect(() => {
        setMenuItemsFiltered(menuItems);
    }, [menuItems]);

    //Functions
    const navigateTo = (path, newScreenId) => {
        if (String(newScreenId) !== String(screenid)) {
            dispatch(setCurrentDevice(null));
            history(path);
        }
    };

    const navigateToFirstScreenCategory = (id, screenType) => {
        let screenToRedirectInfo = null;
        const screensByScreenType = menuItemsFiltered.find((menu) => menu.id === screenType);

        if (screensByScreenType?.screens && screensByScreenType.screens.length > 0) {
            const getDefaultScreenInfo = screensByScreenType.screens.find((screen) => screen.isDefault === true);
            const getFirstScreenInfo = screensByScreenType.screens.find((screen) => screen);

            if (
                getDefaultScreenInfo?.type &&
                (getDefaultScreenInfo.type !== "CCPAIRINGSUCCESSFUL" || getDefaultScreenInfo.type !== "WELCOME")
            ) {
                screenToRedirectInfo = getDefaultScreenInfo;
            } else {
                screenToRedirectInfo = getFirstScreenInfo;
            }
        }

        if (screenToRedirectInfo && String(screenToRedirectInfo.id) !== String(screenid)) {
            dispatch(setCurrentDevice(null));
            history(
                `/design/${id}/screen/${screenToRedirectInfo.type}/${screenToRedirectInfo.id}/${cLang}${
                    landings ? `/${screenToRedirectInfo.landingId}` : ""
                }`
            );
        }
    };

    const firstScreen = (menu) => {
        if (!menu || menu.length === 0) {
            return null;
        }
        let foundMenu = null;
        for (let n = 0; n < menu.length; n++) {
            if (menu[n].screens && menu[n].screens.length > 0) {
                foundMenu = n;
                break;
            }
        }
        if (foundMenu !== null) {
            const firstScreenTV = menu[foundMenu].screens.find((screen) => (screen.type === "TV" ? screen : null));
            if (firstScreenTV) {
                return firstScreenTV;
            }
            const firstScreenMobile = menu[foundMenu].screens.find((screen) =>
                screen.type === "Mobile" ? screen : null
            );
            if (firstScreenMobile) {
                return firstScreenMobile;
            }
            return menu[foundMenu].screens[0];
        }
        return null;
    };

    const addScreen = (screenType) => {
        if (screenType === SCREEN.TYPE.GENERAL) {
            dispatch(
                setExecuteQuery({
                    action: "add-screen-from-template",
                    params: {
                        type: SCREEN.TYPE.GENERAL,
                    },
                })
            );
        } else {
            dispatch(changeActionValues({ "content-type": [screenType] }));
            if (screenType !== SCREEN.TYPE.CAST && screenType !== SCREEN.TYPE.TV) {
                dispatch(changeActionValues({ "content-type": [screenType] }));
            }

            if (screenType === "WIFI") {
                dispatch(changeActionValues({ "content-type": ["PORTAL"] }));
            }

            dispatch(setActionName("add-screen-from-template"));

            // make default first screen create in that group of screen type
            const sGroup = getGroupScreen(screenType);
            const screensData = menuItemsFiltered.filter((screenT) => screenT.id === sGroup);
            let withScreens = false;
            screensData[0].screens.forEach((screen) => {
                if (screen.type === screenType) {
                    withScreens = true;
                }
            });
            dispatch(changeActionValues({ makeDefault: !withScreens }));
            // value to make default first screen

            dispatch(setEditingMenu(false));
            dispatch(setActionItemAffected(id));
            dispatch(setModalContent(AddScreenFromTemplate(id, screenType, null, t, landings)));
            //Launch modal
            dispatch(openModal());
        }
    };

    const getGroupScreen = (screenType) => {
        if (screenType === SCREEN.TYPE.WELCOME || screenType === SCREEN.TYPE.TV || screenType === SCREEN.TYPE.SALES) {
            return SCREEN.TYPE.TV;
        }
        if (screenType === SCREEN.TYPE.CCINSTRUCTION || screenType === SCREEN.TYPE.CCPAIRINGSUCCESSFUL) {
            return SCREEN.TYPE.CAST;
        }
        if (screenType === SCREEN.TYPE.PORTAL) {
            return SCREEN.TYPE.WIFI;
        }
        if (screenType === SCREEN.TYPE.SALESMOBILE) {
            return SCREEN.TYPE.MOBILE;
        }
        return screenType;
    };

    const addFolder = (screens) => {
        let redirect = "";
        if (type && type !== activeCategory) {
            if (screens && screens.length > 0) {
                redirect = `/design/${id}/screen/${activeCategory}/${screens[0].id}/${cLang}${
                    landings ? `/${screens[0].landingId}` : ""
                }`;
            }
        }
        dispatch(setActionName("content-add-folder"));
        dispatch(
            setModalContent(
                AddFolder(t, "add-folder", {
                    contentID: screenid,
                    category: activeCategory,
                    designID: id,
                    redirect: redirect,
                })
            )
        );
        //Launch modal
        dispatch(openModal());
    };

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        setValSearch(value);
        let itemsClone = [];
        if (value && value !== "") {
            if (menuItems) {
                // eslint-disable-next-line
                menuItems.map((item) => {
                    let itemClone = { ...item };
                    let subDirs = [];
                    let addElement = true;
                    itemClone.screens = itemClone.screens.filter((screen) =>
                        normalizeToSearch(screen.name).includes(normalizeToSearch(value))
                    );
                    itemClone.types = [];
                    if (item.id !== "GENERAL") {
                        item.types.forEach((type) => {
                            let _type = filterBySearch({ ...type }, value);
                            if (_type) {
                                itemClone.types.push(_type);
                            }
                        });
                    }
                    if (itemClone.subDirs && itemClone.subDirs.length > 0) {
                        // eslint-disable-next-line
                        itemClone.subDirs.map((subDir) => {
                            let subDirClone = { ...subDir };
                            addElement = true;
                            subDirClone.contents = subDirClone.contents.filter((content) =>
                                normalizeToSearch(content.name).includes(normalizeToSearch(value))
                            );
                            if (!subDirClone.contents || subDirClone.contents.length <= 0) {
                                addElement = normalizeToSearch(subDirClone.name).includes(normalizeToSearch(value));
                            }
                            if (addElement) {
                                subDirs.push(subDirClone);
                            }
                        });
                    }
                    itemClone.subDirs = subDirs;
                    addElement = true;
                    if (
                        (!itemClone.screens || itemClone.screens.length <= 0) &&
                        (!itemClone.subDirs || itemClone.subDirs.length <= 0)
                    ) {
                        addElement = normalizeToSearch(itemClone.name).includes(normalizeToSearch(value));
                    }
                    if (addElement) {
                        itemsClone.push(itemClone);
                    }
                });
            }
        } else {
            itemsClone = menuItems;
        }
        setMenuItemsFiltered(itemsClone);
    };

    const handleCollapsible = async (ref) => {
        let items = [...menuItemsFiltered];
        let elementFound = await getObject(items, "ref", ref, "");
        if (elementFound) {
            let element = elementFound.object;
            if (typeof _.get(items, elementFound.path) != "undefined") {
                element.collapsed = !element.collapsed;
                setMenuItemsFiltered(items);
            }
        }
    };

    function _renderNested(sections, nesteds, currentScreens) {
        sections = sections != null && sections.length > 0 ? sections : [];
        let dynamicObject = null;
        let redirect = "";
        if (nesteds != null && nesteds.length > 0) {
            nesteds.map((item, index) => {
                redirect = "";
                let itemActions = [];
                let nested_elements = [];
                if (type && type !== activeCategory) {
                    if (currentScreens && currentScreens.length > 0) {
                        redirect = `/design/${id}/screen/${activeCategory}/${currentScreens[0].id}/${cLang}${
                            landings ? `/${currentScreens[0].landingId}` : ""
                        }`;
                    }
                }
                itemActions.push({
                    name: "edit-name",
                    action: "content-edit-folder-name",
                    modal_title: "edit-name",
                    modal_value: item.name,
                    modal_btn_name: "rename",
                    mutationParams: {
                        id: item.id,
                        designID: id,
                        redirect: redirect,
                    },
                });
                itemActions.push({
                    name: "new-screen",
                    action: "add-screen-from-template",
                    mutation: "add-screen-from-template",
                    noModal: true,
                    mutationParams: {
                        dirID: item.id,
                        designID: id,
                        type: activeCategory,
                    },
                });
                itemActions.push({
                    name: [t("delete-folder")],
                    action: "content-folder-delete",
                    modal_title: "delete",
                    modal_value: item.name,
                    mutationParams: {
                        id: item.id,
                        designID: id,
                        redirect: redirect,
                    },
                    disabled: item.contents.some((screen) => screen.isLinked),
                    tooltip: item.contents.some((screen) => screen.isLinked) ? t("screens-linked") : "",
                });
                if (item.contents && item.contents.length > 0) {
                    // const multipleTypes = hasMultipleTypes(item.contents);
                    dynamicObject = SortContents(item.contents);
                    dynamicObject.map((screen) => {
                        nested_elements.push(_renderScreenItem(screen, true, item.id));
                        return screen;
                    });
                }
                if (!landings && item.subDirs != null && item.subDirs.length > 0) {
                    nested_elements = _renderNested(nested_elements, item.subDirs, currentScreens);
                }
                sections.push(
                    <motion.li
                        key={index}
                        initial="hidden"
                        animate="visible"
                        exit="close"
                        variants={{
                            visible: {
                                opacity: 1,
                                //height: "auto",
                                transition: {
                                    delay: 0.15,
                                },
                            },
                            hidden: {
                                opacity: 0,
                            },
                            close: {
                                opacity: 0,
                                //height: 0,
                                transition: {
                                    delay: 0.05,
                                },
                            },
                        }}
                    >
                        <div
                            className={`items-center p-0`}
                            onMouseEnter={(e) => setScreenMenuOver({ ref: "folder", id: item.id })}
                            onMouseLeave={(e) => setScreenMenuOver({})}
                        >
                            <div className={`pl-11 truncate mb-2 flex items-center justify-between pt-2 `}>
                                <i
                                    className={`icon-chevron${item.collapsed ? "-right" : ""}`}
                                    onClick={(e) => {
                                        handleCollapsible(`${item.ref}`);
                                    }}
                                ></i>
                                <i className={`icon-folder-outline mx-2 text-xl text-gray-800 `}></i>
                                <div className="truncate inline-block w-10/12 py-1 text-left text-gray-800">
                                    {item.name}
                                </div>
                                <div className="float-right flex text-gray-800 " onClick={(e) => e.stopPropagation()}>
                                    {screenMenuOver &&
                                    screenMenuOver.ref === "folder" &&
                                    parseInt(screenMenuOver.id) === parseInt(item.id) &&
                                    editorMode === EDITOR_MODE.EDITION ? (
                                        <UseItemActions
                                            dataType="content"
                                            id={item.id}
                                            containerAdjust="w-48"
                                            executeSectionQuery={true}
                                            actions={itemActions}
                                            isFolder={true}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        {!item.collapsed ? (
                            <motion.ul
                                id={`ul-${index}`}
                                initial="hidden"
                                animate="visible"
                                exit="close"
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        //height: "auto",
                                        display: "block",
                                        transition: {
                                            when: "beforeChildren",
                                            staggerChildren: 0.03,
                                        },
                                    },
                                    hidden: {
                                        opacity: 0,
                                        //height: 0,
                                        display: "hidden",
                                    },
                                    close: {
                                        opacity: 0,
                                        //height: 0,
                                        display: "hidden",
                                        transition: {
                                            when: "afterChildren",
                                            delay: 0.3,
                                        },
                                    },
                                }}
                                className={`ml-6 ${item.collapsed ? "hidden" : ""}`}
                            >
                                {nested_elements}
                            </motion.ul>
                        ) : null}
                    </motion.li>
                );
                return item;
            });
        }
        return sections;
    }

    const _renderScreenItem = (screen, multipleTypes, dirID) => {
        let contentTreeOptions = [];
        let menuItemsType = menuItems
            ? menuItems.filter((menuItem) => menuItem.id === getGroupScreen(screen.type))
            : null;

        contentTreeOptions.push({
            id: 0,
            name: screen.type,
            adjust: `ml-8`,
            image: `icons/folder.svg`,
            image_adjust: ``,
            visible: true,
        });
        if (menuItemsType && menuItemsType.length > 0 && menuItemsType[0].subDirs) {
            // eslint-disable-next-line
            menuItemsType[0].subDirs.map((subDir) => {
                contentTreeOptions.push({
                    id: subDir.id,
                    name: subDir.name,
                    adjust: `ml-8`,
                    image: `icons/folder.svg`,
                    image_adjust: ``,
                    visible: true,
                });
            });
        }

        let itemActions = [];
        if (screen.__typename === SCREEN.LTYPE.WELCOME) {
            itemActions = getWelcomeItemActions(screen, id, t);
        } else {
            itemActions = getScreenItemActions(screen, id, dirID, contentTreeOptions, landings, t);
        }

        const showTypeTitle = landings && multipleTypes && screen.type !== prevScreenType;
        const showDivider =
            landings &&
            multipleTypes &&
            prevScreenType &&
            screen.type !== prevScreenType &&
            getGroupScreen(prevScreenType) === menuItemsType[0].id;
        prevScreenType = screen.type;

        return (
            <div data-screen={`${screen.type}_${screen.id}`} key={`screen_${screen.id}`}>
                {showDivider ? <div className={`border-b mb-1 mt-2 border-gray-200`}></div> : null}
                {showTypeTitle ? (
                    <div className="flex">
                        <div className="first-capital my-2 font-bold text-gray-800">
                            {t(TRANSLATE_SCREEN_TYPE_TITLE[screen.type])}
                        </div>
                        <div
                            onClick={(e) => addScreen(screen.type)}
                            className="sidebar-menu-link icon icon-add right-0 my-2 -mr-0 ml-auto cursor-pointer pb-0 pt-0 text-xl"
                        ></div>
                    </div>
                ) : null}
                {printListScreenItem(screen, itemActions)}
            </div>
        );
    };

    const printListScreenItem = (screen, itemActions) => {
        const screenID = type === SCREEN.TYPE.WELCOME ? landingid : screenid;
        const screenCurrent = screenID == screen.id; // eslint-disable-line
        const screenIcon =
            screen && screen.type === SCREEN.TYPE.GENERAL
                ? "icon-content" + (screen && screen.isPublished ? "-published" : "")
                : "icon-content" + ((landings && screen.isEnabled) || screen.isPublished ? "-published" : "");
        const screenIconStyle =
            (screenCurrent ? "text-zafiro-400 font-bold" : screen.isEnabled ? "" : "text-gray-600") +
            (landings || screen.isLinked ? "" : " ml-5");

        const isTooltipFem = [SCREEN.TYPE.CCINSTRUCTION, SCREEN.TYPE.CCPAIRINGSUCCESSFUL, SCREEN.TYPE.WELCOME].includes(
            screen.type
        );
        const isFolderContent = screen.finalIndexSubDir;

        const screenTooltip = landings
            ? screen.isDefault
                ? t(`predetermined data${isTooltipFem ? "-fem" : ""}`, {
                      data: t(TRANSLATE_SCREEN_TYPE_TITLE[screen.type]),
                  })
                : screen.isEnabled
                ? t("published")
                : screen.isPublished
                ? t("linked and published")
                : t("unpublished")
            : screen.isPublished
            ? t("linked and published")
            : screen.isLinked
            ? t("linked")
            : screen.isEnabled
            ? t("available")
            : t("unavailable");

        return (
            <li
                key={screen.id}
                className={(landings ? "pl-12" : "pl-5") + " text-gray-800" + (screenCurrent ? " bg-gray-100" : "")}
            >
                {screen.isDefault ||
                ((screen.type === SCREEN.TYPE.SALES || screen.type === SCREEN.TYPE.SALESMOBILE) &&
                    screen.isPublished) ? (
                    <>
                        <div
                            data-tip
                            data-for={`alert_tooltip_${screen.id}`}
                            className={`icon icon-status-solid cursor-help text-xxs text-zafiro-400 relative float-left -ml-4 mt-3`}
                        ></div>

                        <ReactTooltip
                            id={`alert_tooltip_${screen.id}`}
                            delayShow={250}
                            type="light"
                            border={true}
                            borderColor="text-gray-200"
                            arrowColor="text-gray-200"
                            className="font-normal"
                        >
                            <div className="first-capital z505">{`${screenTooltip}`}</div>
                        </ReactTooltip>
                    </>
                ) : null}
                <div data-tip data-for={`icon_tooltip_${screen.id}`}>
                    {!landings && (screen.isLinked || screen.isPublished) ? (
                        <div
                            className={`icon cursor-help icon-action float-left pr-1 pt-2 text-xl ${screenIconStyle}`}
                            style={{
                                ":hover": "text-zafiro-400",
                            }}
                        ></div>
                    ) : null}
                    <div
                        className={`icon cursor-help float-left pr-1 pt-2 text-xl ${screenIcon} ${screenIconStyle}`}
                        style={{
                            ":hover": "text-zafiro-400",
                        }}
                    ></div>
                </div>
                <ReactTooltip
                    id={`icon_tooltip_${screen.id}`}
                    delayShow={250}
                    type="light"
                    border={true}
                    borderColor="text-gray-200"
                    arrowColor="text-gray-200"
                    className="font-normal"
                >
                    <div className="first-capital z505">{screenTooltip}</div>
                </ReactTooltip>
                <div
                    className={`sidebar-submenu-link cursor-pointer ${
                        parseInt(type === SCREEN.TYPE.WELCOME ? landingid : screenid) === parseInt(screen.id)
                            ? "active"
                            : ""
                    }`}
                    onMouseEnter={(e) => setScreenMenuOver({ ref: "screen", id: screen.id })}
                    onMouseLeave={(e) => setScreenMenuOver({})}
                    id={`${
                        screen?.finalIndex >= 0 && designToolbarVisible && type && screen?.type
                            ? `screen-${designToolbarVisible}-type-${screen.type.toLowerCase()}-${screen?.name}${
                                  screen?.finalIndexSubDir >= 0 ? `-dir-${screen.finalIndexSubDir}` : ""
                              }`
                            : null
                    }`}
                    onClick={(e) => {
                        if (location.pathname !== screen.path) {
                            navigateTo(screen.path, screen.id);
                            dispatch(setEditingMenu(false));
                        }
                    }}
                >
                    <div
                        className={`inline-block ${
                            isFolderContent && (screen.isLinked || screen.isPublished) ? "w-7/12" : "w-8/12"
                        } text-left`}
                    >
                        {screen.name}
                    </div>
                    {screenMenuOver &&
                    screenMenuOver.ref === "screen" &&
                    parseInt(screenMenuOver.id) === parseInt(screen.id) &&
                    editorMode === EDITOR_MODE.EDITION ? (
                        <div className="float-right flex text-gray-900" onClick={(e) => e.stopPropagation()}>
                            <UseItemActions
                                dataType="content"
                                id={screen.id}
                                containerAdjust="w-48"
                                executeSectionQuery={true}
                                actions={itemActions}
                            />
                        </div>
                    ) : null}
                </div>
            </li>
        );
    };

    if (error) {
        return <ErrorInfo>{error}</ErrorInfo>;
    }

    return (
        <ul
            id="contentsList"
            className="max-h-80 scrollReference min-w-full list-none flex-col overflow-x-hidden  overflow-y-scroll"
        >
            {showLoading ? (
                <UseLoading />
            ) : (
                <>
                    <div className="flex items-center justify-between px-4 py-2">
                        <div className="relative w-full">
                            <input
                                placeholder={capitalizeFirst(t("search"))}
                                type="text"
                                className={`rounded hover:text-zafiro-400 phNoTransform w-full bg-gray-200  p-2  pl-4 pr-10 text-gray-900`}
                                name="Search"
                                id={`search-input-${prefixId}`}
                                onChange={handleChangeSearch}
                                value={valSearch}
                            ></input>
                            <span className="field-icon icon-search"></span>
                        </div>
                    </div>
                    {!menuItemsFiltered?.length ? (
                        <div className="text-gray-600 px-4 py-2" id="no-results">
                            {t("results-not-found")}
                        </div>
                    ) : (
                        menuItemsFiltered.map((item, index) => {
                            const itemDisplay = (
                                <div
                                    key={`${item.id}_${index}`}
                                    className={`relative flex w-full items-center justify-between pb-3`}
                                    style={{ width: "95%" }}
                                >
                                    <div
                                        className="flex cursor-pointer items-center text-gray-800"
                                        id={`${item?.id?.toLowerCase()}-type-screen`}
                                        onClick={(e) => {
                                            setActiveCategory(activeCategory === item.id ? null : item.id);
                                            dispatch(setEditingMenu(false));
                                            if (item.id !== "GENERAL" && activeCategory !== item.id) {
                                                navigateToFirstScreenCategory(id, item.id);
                                            }
                                        }}
                                    >
                                        <div
                                            id={`${item?.id?.toLowerCase()}-type-screen-icon-arrow`}
                                            className={`${
                                                activeCategory === item.id ? "icon-chevron-up" : "icon-chevron"
                                            }  -mt-1 pl-4`}
                                        ></div>
                                        <div className={`${item.icon} text-35xl`}></div>
                                        <div className=" first-capital ml-5 inline-block font-bold">
                                            {t(item.name === "General" ? "customized screens" : item.name)}
                                        </div>
                                    </div>

                                    {editorMode === EDITOR_MODE.EDITION &&
                                    activeCategory &&
                                    activeCategory === CONTENT_CATEGORY.GENERAL ? (
                                        <div className=" flex">
                                            <div
                                                onClick={(e) => addFolder(menuItems[0].screens)}
                                                className="sidebar-menu-link icon icon-new-folder cursor-pointer p-1 text-xl font-normal"
                                            ></div>
                                            <div
                                                onClick={(e) => addScreen(menuItems[0].id)}
                                                className="sidebar-menu-link icon icon-add cursor-pointer p-1 text-xl"
                                            ></div>
                                        </div>
                                    ) : null}

                                    {editorMode === EDITOR_MODE.EDITION && menuItems && menuItems.length > 1 ? (
                                        <>
                                            {activeCategory && activeCategory === CONTENT_CATEGORY.GENERAL ? (
                                                <div
                                                    onClick={(e) => addFolder(item.screens)}
                                                    className="sidebar-menu-link right-8 icon icon-new-folder absolute -mr-0 cursor-pointer px-0 text-xl"
                                                ></div>
                                            ) : null}
                                        </>
                                    ) : null}
                                </div>
                            );

                            if (typeof item.screens === "undefined") {
                                return (
                                    <li key={item.to}>
                                        <NavLink
                                            className={({ isActive }) =>
                                                `sidebar-menu-link flex items-center ${isActive ? "active" : ""}`
                                            }
                                            to={"/"}
                                        >
                                            {itemDisplay}
                                        </NavLink>
                                    </li>
                                );
                            } else {
                                const refSubmenu = React.createRef();

                                return (
                                    <li key={index} className="py-3">
                                        {itemDisplay}

                                        <ul
                                            ref={refSubmenu}
                                            className={`block overflow-x-hidden  ${
                                                activeCategory !== item.id ? " hidden" : ""
                                            }`}
                                        >
                                            {landings ? (
                                                item.types.map((screenType) => {
                                                    let itemScreens = [];
                                                    if (screenType.landings) {
                                                        itemScreens = SortContents(screenType.landings);
                                                    }
                                                    if (screenType.welcomes) {
                                                        itemScreens = SortContents(screenType.welcomes);
                                                    }
                                                    if (screenType.predefinedScreens) {
                                                        itemScreens = SortContents(screenType.predefinedScreens);
                                                    }
                                                    return (
                                                        <Fragment key={screenType.type}>
                                                            <div className="flex pl-12">
                                                                <div className="first-capital my-2 font-bold text-gray-800">
                                                                    {t(TRANSLATE_SCREEN_TYPE_TITLE[screenType.type])}
                                                                </div>
                                                                {editorMode === EDITOR_MODE.EDITION ? (
                                                                    <div
                                                                        id={`add-new-screen-${screenType.type.toLowerCase()}`}
                                                                        onClick={(e) => addScreen(screenType.type)}
                                                                        className="sidebar-menu-link icon icon-add right-0 my-2 -mr-0 ml-auto cursor-pointer pb-0 pt-0 text-xl"
                                                                    ></div>
                                                                ) : null}
                                                            </div>
                                                            {itemScreens && itemScreens.length > 0 ? (
                                                                itemScreens.map((_screen) => {
                                                                    const landingItemActions = getScreenItemActions(
                                                                        _screen,
                                                                        id,
                                                                        null,
                                                                        null,
                                                                        landings,
                                                                        t
                                                                    );
                                                                    return printListScreenItem(
                                                                        _screen,
                                                                        landingItemActions
                                                                    );
                                                                })
                                                            ) : (
                                                                <span
                                                                    className="pl-12 text-gray-500"
                                                                    id={`no-screens-yet-${screenType.type}`}
                                                                >
                                                                    {t("no-screens-yet")}
                                                                </span>
                                                            )}
                                                        </Fragment>
                                                    );
                                                })
                                            ) : (
                                                <>
                                                    {item.screens.map((screen) => {
                                                        return _renderScreenItem(screen, true, 0);
                                                    })}
                                                    <AnimatePresence>
                                                        <ul className="min-w-full list-none flex-col">
                                                            {_renderNested([], item.subDirs, item.screens)}
                                                        </ul>
                                                    </AnimatePresence>
                                                </>
                                            )}
                                        </ul>
                                    </li>
                                );
                            }
                        })
                    )}
                </>
            )}
        </ul>
    );
}

const findScreen = (search, item) => {
    let exists = false;
    if (item) {
        const scrs = item.screens || item.contents;
        const dirs = item.subDirs;
        if (scrs) {
            scrs.map((s) => {
                if (s && parseInt(s.id) === parseInt(search)) {
                    exists = true;
                }
                return s;
            });
        }
        if (dirs) {
            dirs.map((d) => {
                if (findScreen(search, d)) {
                    exists = true;
                }
                return d;
            });
        }
    }
    return exists;
};

export const ParseContentTree = (data, permissions, filterDeviceType) => {
    if (data?.screens?.categories) {
        return data.screens.categories
            .filter((content) => {
                const isTV = filterDeviceType === DEVICE.TYPE.TV;
                const isMobile = filterDeviceType === DEVICE.TYPE.MOBILE;
                switch (content.category) {
                    case CONTENT_CATEGORY.CAST:
                        return (!filterDeviceType || isTV) && (!permissions || permissions?.cast);
                    case CONTENT_CATEGORY.TV:
                        return (!filterDeviceType || isTV) && (!permissions || permissions?.tv);
                    case CONTENT_CATEGORY.MOBILE:
                        return (!filterDeviceType || isMobile) && (!permissions || permissions?.mobile);
                    case CONTENT_CATEGORY.GENERAL:
                        return !permissions || permissions?.general;
                    case CONTENT_CATEGORY.WIFI:
                        return permissions?.wifi;
                    default:
                        console.error("Unknown screen type: " + content.category);
                        return false;
                }
            })
            .sort((a, b) => {
                if (a && b && a.category !== b.category) {
                    const list = Object.keys(CONTENT_CATEGORY);
                    return list.indexOf(a.category) < list.indexOf(b.category) ? 1 : -1;
                }
                return 0;
            })
            .map((content) => {
                //TODO
                return content;
            });
    }
    return data.screens.dir;
};

export const ParseScreenTypes = (screenTypes, perms) => {
    if (screenTypes) {
        return screenTypes
            .filter((screenType) => {
                switch (screenType.type) {
                    case SCREEN.TYPE.WELCOME:
                        return !perms || perms.welcome;
                    case SCREEN.TYPE.SALES:
                    case SCREEN.TYPE.SALESMOBILE:
                        return !perms || perms.sales;
                    default:
                        return true;
                }
            })
            .map((content) => {
                //TODO
                return content;
            });
    }
    return screenTypes;
};

const orderTVScreenTypes = (types) => {
    let typesOrdered = [];
    const tv = types.find((type) => type.type === SCREEN.TYPE.TV);
    const welcome = types.find((type) => type.type === SCREEN.TYPE.WELCOME);
    const sales = types.find((type) => type.type === SCREEN.TYPE.SALES);
    if (tv) {
        typesOrdered.push(tv);
    }
    if (welcome) {
        typesOrdered.push(welcome);
    }
    if (sales) {
        typesOrdered.push(sales);
    }
    return typesOrdered.length > 0 ? typesOrdered : types.reverse();
};

export const SortContents = (contentsList) => {
    if (!contentsList || contentsList.length === 0) {
        return contentsList;
    }
    contentsList.sort((a, b) => {
        if (a && b) {
            if (a.isDefault !== b.isDefault) {
                return a.isDefault ? -1 : 1;
            }
            if (a.isPublished !== b.isPublished) {
                return a.isPublished ? -1 : 1;
            }
            if (a.isLinked !== b.isLinked) {
                return a.isLinked ? -1 : 1;
            }
            if (a.isEnabled !== b.isEnabled) {
                return a.isEnabled ? -1 : 1;
            }
            if (a.isAssigned !== b.isAssigned) {
                return a.isAssigned ? -1 : 1;
            }
            return a.name.localeCompare(b.name);
        }
        return 0;
    });

    contentsList.sort((a, b) => a.type.localeCompare(b.type));

    return contentsList;
};

export const getObject = async (theObject, keyToFind, valueToFind, actualPath) => {
    let result = null;
    if (theObject instanceof Array) {
        for (let i = 0; i < theObject.length; i++) {
            result = await getObject(theObject[i], keyToFind, valueToFind, actualPath);
            if (result) {
                actualPath = `[${i}]${actualPath}`;
                result.path = actualPath;
                break;
            }
        }
    } else {
        if (theObject instanceof Object) {
            for (let prop in theObject) {
                if (prop === keyToFind) {
                    if (theObject[prop] === valueToFind) {
                        return { object: theObject, path: actualPath };
                    }
                }
                if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                    actualPath += `.${prop}`;
                    result = await getObject(theObject[prop], keyToFind, valueToFind, actualPath);
                    if (result) {
                        break;
                    }
                }
            }
        }
    }
    return result;
};

const addContinuousFinalIndexIncludingSubDirs = (screensArray) => {
    let finalIndexCounter = 1;

    const addFinalIndexToScreens = (screens, subDirIndex = null) => {
        screens.forEach((screen) => {
            screen.finalIndex = finalIndexCounter++;
            if (subDirIndex !== null) {
                screen.finalIndexSubDir = subDirIndex;
            }
        });
    };

    screensArray.forEach((screenGroup) => {
        if (screenGroup.screens && screenGroup.screens.length > 0) {
            addFinalIndexToScreens(screenGroup.screens);
        }

        if (screenGroup.subDirs && screenGroup.subDirs.length > 0) {
            screenGroup.subDirs.forEach((subDir, subDirIndex) => {
                if (subDir.contents && subDir.contents.length > 0) {
                    addFinalIndexToScreens(subDir.contents, subDirIndex + 1);
                }
            });
        }
    });

    return screensArray;
};
