import React, { useRef, forwardRef, useImperativeHandle, useContext } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { CSVLink } from "react-csv";

import { GlobalContext } from "contexts/Global";

const DESIGN = {
    BLUE: "blue",
    BLUE_OUTLINE: "blue-outline",
    DARK_BLUE: "dark-blue",
    RED: "red",
    WHITE: "white",
    LINK: "link",
    TEXT_LINK: "text-link",
    CLASSIC_LINK: "classic-link",
};

/**
 * This component is a button with actions
 * @param {JSX.Element} children The content of the button
 * @param {string} id The id of the button
 * @param {string} design The design of the button (blue, blue-outline,  dark-blue, red, white, link, text-link,classic-link)
 * @param {boolean} narrow The flag to make the button narrow (default: false)
 * @param {boolean} disabled The flag to disable the button
 * @param {boolean} readOnly The flag to make the button read-only
 * @param {function} onClick The function to call when the button is clicked
 * @param {string} href The link to redirect when the button is clicked
 * @param {string} to The link to redirect when the button is clicked
 * @param {string} tooltip The text of the tooltip
 * @param {string} tooltipType The type of the tooltip
 * @param {string} className The class name of the button
 * @returns {JSX.Element} The UI of the component
 * @example <Button id="button-id" onClick={handleClick}>Button</Button>
 */
const Button = forwardRef(
    (
        {
            children,
            id,
            action,
            actionData,
            design,
            narrow,
            disabled,
            readOnly,
            onClick,
            className,
            href,
            to,
            tooltip,
            tooltipType,
            tabIndex,
        },
        ref
    ) => {
        const buttonRef = useRef(null);

        const { highlightComponent } = useContext(GlobalContext);

        if (!id) {
            throw new Error("The button must have an id");
        }
        if (onClick && typeof onClick !== "function") {
            throw new Error("The onClick prop must be a function");
        }
        if (href && typeof href !== "string") {
            throw new Error("The href prop must be a string");
        }

        useImperativeHandle(ref, () => ({
            focus: () => {
                if (buttonRef.current) {
                    buttonRef.current.focus();
                    return true;
                }
                return false;
            },
            activeFocus: () => {
                if (buttonRef?.current) {
                    return document.activeElement === buttonRef.current;
                }
                return false;
            },
            click: () => {
                if (buttonRef.current) {
                    buttonRef.current.click();
                }
            },
        }));

        const isSolid = design && ![DESIGN.LINK, DESIGN.CLASSIC_LINK, DESIGN.TEXT_LINK].some((d) => d === design);

        const buttonClass = classNames({
            // Flex and spacing
            flex: true,
            "space-x-2": !className?.includes("space-x-"),
            "items-center": !className?.includes("items-"),
            "whitespace-no-wrap": design,

            // Blue
            "bg-blue-300 text-white hover:bg-blue-200 focus:bg-blue-200 active:bg-blue-400":
                design === DESIGN.BLUE && !disabled,

            // Blue outline
            "bg-white text-blue-300 border border-blue-300 hover:border-blue-200 hover:bg-gray-200 focus:border-blue-200 focus:bg-gray-200 active:bg-gray-300":
                design === DESIGN.BLUE_OUTLINE && !disabled,

            // Red
            "bg-red-100 text-white hover:bg-red-600 focus:bg-red-600 active:bg-red-800":
                design === DESIGN.RED && !disabled,

            // Basic
            "text-black hover:text-gray-900 focus:text-gray-900": design === "basic" && !disabled,

            // Dark blue
            "bg-blue-800 text-white hover:bg-blue-700 focus:bg-blue-700 active:bg-blue-900":
                design === DESIGN.DARK_BLUE && !disabled,

            // White
            "bg-white text-blue-300 border border-transparent hover:border-gray-100 focus:border focus:border-gray-100":
                design === DESIGN.WHITE && !disabled,

            // Disabled
            "bg-gray-300 text-white": disabled && [DESIGN.RED, DESIGN.BLUE, DESIGN.DARK_BLUE].some((d) => d === design),
            "border border-gray-400 bg-white text-gray-700":
                disabled && [DESIGN.BLUE_OUTLINE, "basic", DESIGN.WHITE].some((d) => d === design),

            // Links
            "font-bold": design === DESIGN.LINK,

            "inline-block flex items-center justify-start": [DESIGN.LINK, DESIGN.CLASSIC_LINK, DESIGN.TEXT_LINK].some(
                (d) => d === design
            ),
            "text-zafiro-600": [DESIGN.LINK, DESIGN.CLASSIC_LINK].some((d) => d === design) && !disabled,
            "hover:text-zafiro-400 focus:text-zafiro-400 active:text-zafiro-800":
                [DESIGN.LINK, DESIGN.TEXT_LINK, DESIGN.CLASSIC_LINK].some((d) => d === design) && !disabled,
            "text-gray-700": [DESIGN.LINK, DESIGN.CLASSIC_LINK, DESIGN.TEXT_LINK].some((d) => d === design) && disabled,

            "hover:underline focus:underline": design === DESIGN.CLASSIC_LINK && !disabled,
            underline: design === DESIGN.CLASSIC_LINK && disabled,

            // Solid button
            "rounded px-5 inline-block flex items-center justify-center": isSolid,
            "active:shadow-inner": design && isSolid && design !== "basic" && !disabled,

            // Cursor states
            "cursor-pointer": !disabled && !readOnly,
            "cursor-default": disabled || readOnly,

            // New component
            "highlight-component": highlightComponent,

            // Custom className
            [className]: className,
        });

        const buttonStyle = {
            ...(isSolid
                ? {
                      letterSpacing: "0.005em",
                      paddingTop: "0.3933rem",
                      paddingBottom: "0.3933rem",
                  }
                : null),
            ...(isSolid && !narrow ? { minWidth: "8.858rem" } : null),
        };

        const buttonProps = {
            id,
            ref: buttonRef,
            className: buttonClass,
            tabIndex: tabIndex || 0,
            style: buttonStyle,
            href: disabled || readOnly ? "#" : href,
            onClick: (...args) => {
                if (disabled || readOnly) {
                    return;
                }
                if (onClick) {
                    onClick(...args);
                }
            },
            ...(tooltip
                ? {
                      "data-tip": tooltip,
                      "data-for": tooltipType || "default-tooltip",
                  }
                : {}),
        };

        if (action === "csv") {
            return (
                <CSVLink {...buttonProps} {...actionData}>
                    {children}
                </CSVLink>
            );
        }

        if (to) {
            return (
                <NavLink {...buttonProps} to={to}>
                    {children}
                </NavLink>
            );
        }

        if (href) {
            return (
                <a {...buttonProps} target="_blank" rel="noopener noreferrer">
                    {children}
                </a>
            );
        }

        return <button {...buttonProps}>{children}</button>;
    }
);
Button.displayName = "Button";

export default Button;
