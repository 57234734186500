import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Select from "components/Select";
import Modal, { useModal } from "components/Modal";
import { useAuth } from "hooks/Session/auth";
import ProjectName from "components/ProjectName";
import useBrands from "hooks/Data/useBrands";

import { VISIBILITY } from "constants/design";

const ManageProjects = ({ design }) => {
    const { t } = useTranslation();
    const { open, close } = useModal();
    const { chain } = useAuth();

    const brands = useBrands();
    const { allProjects } = useAuth();

    // This condition is to check if current user has access to all projects
    const manageAll = !allProjects?.some((p) => p.disabled);

    const [designDestinations, setDesignDestinations] = useState(design?.visibilityScope);

    const designProjects = design?.projectsInUse;

    const isDestinationAll = designDestinations?.some((d) => d.ref === VISIBILITY.ALL_PROJECTS);

    const destinationSelected = designDestinations?.length ? designDestinations?.map((p) => p.ref) : [];

    // is destinationSelected and add ref of project of destination of type "brand"
    const selected = destinationSelected?.reduce((acc, curr) => {
        if (curr === VISIBILITY.ALL_PROJECTS) {
            return acc.concat(
                allProjects.map((p) => p.ref),
                [VISIBILITY.ALL_PROJECTS]
            );
        }
        const brand = brands?.data?.find((b) => b.ref === curr);
        if (brand) {
            return acc.concat(
                brand.projects.map((p) => p.ref),
                [brand.ref]
            );
        }
        return acc.concat(curr);
    }, []);

    const projectOptions = allProjects?.length
        ? allProjects.map((project) => {
              const alreadyInDesign = designProjects?.find((d) => d.projectRef === project.ref);
              const hasDefaultDesign = alreadyInDesign ? alreadyInDesign?.hasDefaultDesign : null;
              const allowChange = !project?.disabled && (!alreadyInDesign || hasDefaultDesign);
              const indirectSelected = selected?.includes(project.ref) && !destinationSelected?.includes(project.ref);
              const reason = project?.disabled
                  ? t("This property cannot be changed since it is not available to manage")
                  : t("This property cannot be removed since it would be left without a design applied");
              return {
                  label: <ProjectName name={project.name} hasChainModule={project.hasChainModule} />,
                  value: project.ref,
                  disabled: !allowChange || (!isDestinationAll && indirectSelected),
                  hasDefaultDesign,
                  tooltip: !allowChange ? reason : null,
              };
          })
        : [];

    const brandsOptions = brands?.data?.length
        ? brands?.data?.map((brand) => {
              const brandProjects = brand?.projects?.length ? brand.projects.map((p) => p.ref) : [];
              const alreadyInDesign = designDestinations?.find((d) => d.ref === brand.ref);
              const allowChange =
                  !brand?.disabled &&
                  (!alreadyInDesign ||
                      !projectOptions
                          .filter((p) => brandProjects.includes(p?.value))
                          .some((p) => p.hasDefaultDesign === false));
              const reason = brand?.disabled
                  ? t("This group cannot be changed since some properties are not available to manage")
                  : t("This group cannot be removed since some properties would be left without a design applied");
              return {
                  label: brand.name,
                  value: brand.ref,
                  disabled: !allowChange || isDestinationAll,
                  tooltip: !allowChange ? reason : null,
              };
          })
        : [];

    const allowChainChange =
        manageAll && (!isDestinationAll || !projectOptions.some((p) => p.hasDefaultDesign === false));
    const chainChangeReason = !manageAll
        ? t("Some properties are not available to manage")
        : t("This chain cannot be removed since some properties would be left without a design applied");

    //TODO DESIGNS allow changes only in user projects (project.disabled=false)
    const destinationOptions = [
        {
            label: chain?.name,
            value: VISIBILITY.ALL_PROJECTS,
            disabled: !allowChainChange,
            tooltip: !allowChainChange ? chainChangeReason : null,
        },
        {
            label: t("groups"),
            options: brandsOptions,
            disabled: isDestinationAll,
        },
        {
            label: t("properties"),
            options: projectOptions,
        },
    ];

    useEffect(() => {
        brands.load();
    }, []);

    const ready = brands?.ready;
    const error = brands?.error;

    return (
        <Modal
            title={`${design?.name} - ${t("manage-properties")}`}
            width="40rem"
            loading={!ready && !error}
            error={error}
            footer={
                <div className="flex justify-end gap-4 mt-6">
                    <Button id="cancel" className="font-bold" design="white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        id="save"
                        design="blue"
                        onClick={() => {
                            //TODO DESIGNS update design properties
                            open(
                                <Modal id="under-development" title="Coming soon">
                                    <div>Selected properties:</div>
                                    <pre>{JSON.stringify(designDestinations, null, 2)}</pre>
                                </Modal>
                            );
                            //close();
                        }}
                    >
                        {t("save")}
                    </Button>
                </div>
            }
        >
            <div className="flex flex-col gap-2">
                <p className="text-gray-900">{t("design-properties-instructions")}</p>
                <Select
                    id="properties"
                    placeholder={t("select-properties")}
                    options={destinationOptions}
                    value={selected}
                    selectGroup={false}
                    multiple={true}
                    onChange={(values) => {
                        if (isDestinationAll && !values?.includes(VISIBILITY.ALL_PROJECTS)) {
                            setDesignDestinations([]);
                        } else if (!isDestinationAll && values?.includes(VISIBILITY.ALL_PROJECTS)) {
                            setDesignDestinations([{ ref: VISIBILITY.ALL_PROJECTS }]);
                        } else {
                            const brandValues = values?.filter((v) => v.startsWith("brand"));
                            const projectValues = values?.filter((v) => v.startsWith("project"));

                            const currentBrands = designDestinations
                                ?.filter((d) => d.type === VISIBILITY.BRAND)
                                .map((d) => d.ref);
                            const deselectedBrands = currentBrands?.filter((b) => !brandValues?.includes(b));

                            const deselectedBrandsProjects = deselectedBrands?.reduce((acc, curr) => {
                                const brand = brands?.data?.find((b) => b.ref === curr);
                                if (brand) {
                                    return acc.concat(brand.projects.map((p) => p.ref));
                                }
                                return acc;
                            }, []);

                            const selectedBrandsProjects = brandValues?.reduce((acc, curr) => {
                                const brand = brands?.data?.find((b) => b.ref === curr);
                                if (brand) {
                                    return acc.concat(brand.projects.map((p) => p.ref));
                                }
                                return acc;
                            }, []);

                            const filteredValues = brandValues?.concat(
                                projectValues?.filter(
                                    (ref) =>
                                        !selectedBrandsProjects.includes(ref) && !deselectedBrandsProjects.includes(ref)
                                )
                            );
                            setDesignDestinations(
                                filteredValues?.length
                                    ? filteredValues.map((v) => ({
                                          type: v.startsWith("brand") ? VISIBILITY.BRAND : VISIBILITY.PROJECT,
                                          ref: v,
                                      }))
                                    : []
                            );
                        }
                    }}
                />
            </div>
        </Modal>
    );
};

export default ManageProjects;
