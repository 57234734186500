import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { Session } from "hooks/Utils/Session";
import { usePermissions } from "hooks/Data/useUser";

import { useTokenLanguage } from "./auth";

const useProjectAuth = ({ user, onError }) => {
    const storedProject = Session.getProject();

    const { t } = useTranslation();

    const [state, setState] = useState({
        info: storedProject,
        chainPermissions: null,
        permissions: null,
        accesses: undefined,
        dhcp: null,
        languages: null,
        defaultLanguage: null,
        projects: null,
        projectsWithDesigns: null,
    });

    const token = state?.info?.token;

    const { update: updateToken, token: updatedToken, loading } = useTokenLanguage({ onError });

    const clearPermissions = () => {
        setState((prev) => ({
            ...prev,
            permissions: null,
            accesses: undefined,
            chainPermissions: null,
            dhcp: null,
            languages: null,
            projects: null,
            defaultLanguage: null,
        }));
    };

    const { load: getPermissions, ready: permissionsReady } = usePermissions({
        token,
        userID: user.info?.id || null,
        projectID: state?.info?.id || null,
        projectCode: state?.info?.code || null,
        onSuccess: (data) => {
            //TODO refresh this info every x time (5/10 minutes)
            const roles = data?.userPermissions?.results?.[0]?.roles || [];
            const accesses = extractAccessesFromRoles(roles) || null;
            const languages =
                data?.projectLangs?.results || data?.distinctProjectLangs?.results?.map((l) => ({ languageRef: l }));
            const defaultLanguage = data?.projectLangs
                ? data?.projectLangs?.results?.find((l) => l?.isDefault)?.languageRef
                : data?.distinctProjectLangs?.results?.[0]?.languageRef;
            setState((prev) => ({
                ...prev,
                permissions: data?.permissions,
                chainPermissions: data?.chainPermissions,
                accesses,
                dhcp: data?.dhcp,
                projects: data?.projects?.results?.length
                    ? data?.projects?.results?.map((p) => {
                          p.designID = data?.propertiesWithDesigns?.length
                              ? data.propertiesWithDesigns.find((d) => d?.projectID === p?.id && d?.designID)?.designID
                              : null;
                          return p;
                      })
                    : null,
                projectsWithDesigns: data?.propertiesWithDesigns,
                languages,
                defaultLanguage,
            }));
        },
        onError: () => {
            return (err) => {
                console.error(err);
                toast.error(t("mutation-error"));
            };
        },
    });

    useEffect(() => {
        Session.setProject(state?.info);
    }, [state?.info]);

    useEffect(() => {
        if (updatedToken) {
            setState((prev) => ({ ...prev, token: updatedToken }));
        }
    }, [updatedToken]);

    useEffect(() => {
        if (token) {
            getPermissions({ token });
        }
    }, [token]);

    useEffect(() => {
        if (user.info?.superUser || state?.accesses) {
            const hasNetworkAlerts = state?.accesses?.includes("networkAlerts");
            const isEnabled = (hasNetworkAlerts || user.info?.superUser) && state?.dhcp?.isAllData;
            Session.setSessionProp("networkWarnings", isEnabled);
        } else {
            Session.removeSessionProp("networkWarnings");
        }
    }, [state?.dhcp, user.info?.superUser, state?.accesses]);

    return {
        info: state?.info,
        dhcp: state?.dhcp,
        projects: state?.projects,
        projectsWithDesigns: state?.projectsWithDesigns,
        accesses: state?.accesses,
        chainPermissions: state?.chainPermissions,
        permissions: state?.permissions,
        languages: state?.languages,
        defaultLanguage: state?.defaultLanguage,
        token,
        updateLanguage: (lang) => updateToken({ lang, token }),
        loading,
        ready: !token || (permissionsReady && state?.accesses !== undefined),
        login: (project, token) => {
            clearPermissions();
            setState({
                info: {
                    ...project,
                    token,
                },
            });
        },
        logout: () => {
            setState(null);
        },
    };
};

const extractAccessesFromRoles = (userRoles) => {
    return userRoles
        .map((role) => role.accesses.map((accesse) => accesse.name))
        .flat()
        .filter((v, i, a) => a.indexOf(v) === i);
};

export default useProjectAuth;
