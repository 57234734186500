import React, { useState, useEffect, useCallback, useMemo } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import TextInput from "components/TextInput";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { Session } from "hooks/Utils/Session";
import { useMutation, useLazyQuery } from "react-apollo";
import {
    CREATE_MOBILE_APP,
    MUTATION_VALIDATE_CREATE_MOBILE,
    QUERY_MOBILE_APP_DOMAIN_AND_PROJECT_PERMISSIONS,
    QUERY_MOBILE_APPS,
} from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { setExecuteQuery } from "actions/sectionActions";
import { toast } from "react-toastify";
import { forbiddenWords } from "hooks/Utils/ForbiddenWords";
import HTMLReactParser from "html-react-parser";
import { PERMISSION, CORPORATE_REF } from "constants/permissions";
import Select from "components/Select";
import Loading from "components/Loading";

const useMobileDomainAndPermissions = () => {
    const projects = useMemo(() => Session.getProjects() || [], []);
    const projectsCode = useMemo(() => projects.filter((p) => p.code).map((p) => p.code), [projects]);

    const [mobileAppDomain, setMobileAppDomain] = useState("testing.zafiro.link");
    const [projectsWithMobile, setProjectsWithMobile] = useState([]);
    const [loading, setLoading] = useState(true);

    const [executeQuery] = useLazyQuery(QUERY_MOBILE_APP_DOMAIN_AND_PROJECT_PERMISSIONS({ projectsCode }), {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data?.getSystemConfigs?.mobileAppDomain) {
                setMobileAppDomain(data.getSystemConfigs.mobileAppDomain);
            }
            if (projectsCode.length > 0) {
                const REQUIRED_PERMISSIONS = [PERMISSION.productMOBILE, PERMISSION.inLoyaltyApp];
                const newProjectsWithMobile = projectsCode.filter((p) => {
                    const permissions = data?.[`permissions${p}`] || [];
                    return REQUIRED_PERMISSIONS.every((perm) => {
                        return permissions.includes(perm);
                    });
                });
                setProjectsWithMobile(newProjectsWithMobile);
            }
            setLoading(false);
        },
        onError: () => {
            setLoading(false);
        },
    });

    useEffect(() => {
        setLoading(true);
        executeQuery();
    }, [executeQuery]);

    return { mobileAppDomain, projectsWithMobile, loading };
};

const useCreateMobileApp = ({ nameApp, mobileAppDomain, propertiesChecked, close, action, setValidName, monoApp }) => {
    const dispatch = useDispatch();
    const [url, setUrl] = useState("");
    const { t } = useTranslation();

    const parseURL = useCallback(() => {
        const appName = nameApp || "";
        return `${appName.trim().replace(/\s+/g, "_").toLowerCase()}.${mobileAppDomain}`;
    }, [nameApp, mobileAppDomain]);

    useEffect(() => {
        setUrl(parseURL());
    }, [parseURL]);

    const [executeMutation, { data }] = useMutation(MUTATION_VALIDATE_CREATE_MOBILE({ url }));

    useEffect(() => {
        if (data?.validateCreateMobileApp?.ok) {
            dispatch(
                setExecuteQuery({
                    action: action || CREATE_MOBILE_APP,
                    params: {
                        data: {
                            url,
                            propertiesChecked,
                            nameApp,
                            refMono: monoApp ? Session.getProject()?.ref : null,
                        },
                    },
                })
            );
            close();
        } else if (data?.validateCreateMobileApp?.ok === false) {
            toast.error(t("there-is-already-an-app-with-this-name"));
            setValidName(false);
        }
    }, [data]);

    return { executeMutation };
};

const useGetMobileApps = () => {
    const [projectsRefsWithApps, setProjectsRefsWithApps] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [executeQuery, { data }] = useLazyQuery(QUERY_MOBILE_APPS(), {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data?.mobileApps?.results) {
                const projectsRefsWithApps = data.mobileApps.results.map((app) => ({
                    appName: app.name,
                    projectRefs: app.projects.map((project) => project.ref),
                    appId: app?.id,
                }));
                setProjectsRefsWithApps(projectsRefsWithApps);
            }
            setLoading(false);
        },
        onError: (queryError) => {
            setError(queryError);
            setLoading(false);
        },
    });

    useEffect(() => {
        setLoading(true);
        executeQuery();
    }, [executeQuery]);

    return { projectsRefsWithApps, loading, error };
};
const ModalAddApp = ({ close, monoApp, action, manageProperties }) => {
    const { t } = useTranslation();
    const [propertiesData, setPropertiesData] = useState([]);
    const [propertiesChecked, setPropertiesChecked] = useState([]);
    const [nameApp, setNameApp] = useState("");
    const [validName, setValidName] = useState(true);
    const {
        mobileAppDomain,
        projectsWithMobile,
        loading: loadingMobileDomainAndPermissions,
    } = useMobileDomainAndPermissions();
    const { projectsRefsWithApps, loading: loadingProjectsWithRefApps } = useGetMobileApps();
    const { executeMutation } = useCreateMobileApp({
        nameApp,
        mobileAppDomain,
        propertiesChecked,
        close,
        action,
        setValidName,
        monoApp,
    });
    const projects = useCallback(() => Session.getProjects() || [], []);

    const { permissions } = useSelector((state) => state.ui);
    const nativeApps = permissions?.hotel?.nativeApps || permissions?.allProperties?.nativeApps;

    console.log(loadingMobileDomainAndPermissions);
    console.log(loadingProjectsWithRefApps);

    useEffect(() => {
        if (!loadingMobileDomainAndPermissions && !loadingProjectsWithRefApps) {
            const refToAppNameMap = projectsRefsWithApps.reduce((acc, app) => {
                app.projectRefs.forEach((ref) => {
                    acc[ref] = { appName: app.appName, appId: app.appId };
                });
                return acc;
            }, {});

            let allRefs = Object.keys(refToAppNameMap);

            if (manageProperties?.currentProjectsRefs) {
                setPropertiesChecked(manageProperties?.currentProjectsRefs);
            }

            const options = projects()
                .filter((p) => p.ref !== CORPORATE_REF && projectsWithMobile.includes(p.code))
                .map((p) => {
                    const disabled =
                        !allRefs.includes(p.ref) ||
                        (manageProperties && manageProperties.appId === refToAppNameMap[p.ref]?.appId)
                            ? false
                            : true;

                    return {
                        value: p.ref,
                        label: p.name || "",
                        disabled: disabled,
                        tooltip:
                            allRefs.includes(p.ref) && disabled
                                ? t("property-already-associated-with-app", { app: refToAppNameMap[p.ref]?.appName })
                                : "",
                        id: p.ref + "app-id",
                    };
                });

            const newPropertiesData = [
                {
                    label: t("all-properties-word"),
                    options: options,
                },
            ];
            setPropertiesData((prev) =>
                JSON.stringify(prev) !== JSON.stringify(newPropertiesData) ? newPropertiesData : prev
            );
        }
    }, [projects, projectsRefsWithApps, projectsWithMobile]);

    const handleCloseClick = () => typeof close === "function" && close();

    const handleCreateClick = () => {
        if (!manageProperties) {
            if (!nameApp?.trim()) {
                setValidName(false);
                toast.error(t("input error"));
                return;
            }

            const containsForbiddenWord = forbiddenWords.some((forbiddenWord) =>
                nameApp.toLowerCase().includes(forbiddenWord.toLowerCase())
            );

            if (containsForbiddenWord) {
                setValidName(false);
                toast.error(t("input error"));
            } else {
                executeMutation();
                setValidName(true);
            }
        } else {
            action({ propertiesChecked: propertiesChecked });
            close();
        }
    };

    return (
        <Modal
            title={t(manageProperties ? "manage-properties" : "add-app")}
            footer={
                !loadingProjectsWithRefApps && !loadingMobileDomainAndPermissions ? (
                    <>
                        <Button design="blue-outline" id={"close-click-modal"} onClick={handleCloseClick}>
                            {t("cancel")}
                        </Button>
                        <Button id={"create-click-modal"} design="blue" onClick={handleCreateClick}>
                            {capitalizeFirst(t(manageProperties ? "save" : "add"))}
                        </Button>
                    </>
                ) : null
            }
            className="w-4/12 p-10"
        >
            {loadingProjectsWithRefApps || loadingMobileDomainAndPermissions ? (
                <Loading />
            ) : (
                <div>
                    {!manageProperties ? (
                        <>
                            <span className=" block font-bold text-gray-800 mb-2 ">{t("name")} *</span>
                            <TextInput
                                className={validName ? "border border-transparent" : "border border-red-100"}
                                id={"name-app-input"}
                                value={nameApp}
                                placeholder={t("app-name")}
                                onChange={setNameApp}
                            />
                        </>
                    ) : (
                        <span>{t("select-the-properties-that-will-have-access-to-it")}</span>
                    )}
                    {!monoApp && !loadingProjectsWithRefApps && !loadingMobileDomainAndPermissions ? (
                        <>
                            <span className=" block mt-6 mb-2 font-bold text-gray-800 ">{t("properties")} </span>
                            <Select
                                id="select-properties-add-modal-app"
                                options={propertiesData}
                                multiple={true}
                                value={propertiesChecked}
                                placeholder={t("select-an-option")}
                                onChange={(values) => {
                                    if (values) {
                                        setPropertiesChecked(values);
                                    } else {
                                        setPropertiesChecked([]);
                                    }
                                }}
                            />
                        </>
                    ) : null}
                    {nativeApps ? (
                        <div className="mt-2 flex">
                            <i className="icon icon-warning text-orange-100 text-xl pr-2 pt-1" />
                            <span className=" text-left">{HTMLReactParser(t("add-native-app-message"))}</span>
                        </div>
                    ) : null}
                </div>
            )}
        </Modal>
    );
};

const useAddModalApp = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalAddApp {...newProps} />);
        },
    };
};

export default useAddModalApp;
