import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "hooks/Session/auth";
import Modal, { useModal } from "components/Modal";
import { toast } from "react-toastify";
import Button from "components/Button";
import TextInput from "components/TextInput";
import Select from "components/Select";
import useThemes from "hooks/Data/useThemes";
import { useCreateDesign, useUpdateDesign } from "hooks/Data/useDesigns";
import { THEME_TYPE, DESIGN_TYPE } from "constants/design";

const DesignForm = ({ design: data, onSuccess, onError }) => {
    const modalRef = useRef(null);
    const inputNameRef = useRef(null);
    const inputThemeRef = useRef(null);

    const { t } = useTranslation();
    const { isCorporate } = useAuth();
    const { close } = useModal();
    const themes = useThemes();
    const queryCallbacks = {
        onSuccess: (response) => {
            toast.success(t("operation-successful"));
            if (onSuccess) {
                onSuccess(response);
            }
            close();
        },
        onError: (err) => {
            toast.error(t("mutation-error"));
            if (onError) {
                onError(err);
            }
        },
    };
    const create = useCreateDesign(queryCallbacks);
    const update = useUpdateDesign(data?.id, queryCallbacks);

    const isEditing = !!data?.id;
    const nameValue = data?.name || "";
    const themeValue = data?.themeID || (themes?.data?.length ? themes?.data?.[0]?.id : null);

    const save = () => {
        if (!inputNameRef.current || !inputThemeRef.current) {
            return;
        }
        if (!inputNameRef.current.isValid() || !inputThemeRef.current.isValid()) {
            toast.error(t("errors-in-red"));
            return;
        }
        if (isEditing) {
            update.post({
                name: inputNameRef.current.getValue(),
                themeID: inputThemeRef.current.getValue(),
            });
        } else {
            create.post({
                name: inputNameRef.current.getValue(),
                type: isCorporate ? DESIGN_TYPE.CORPORATE : DESIGN_TYPE.LOCAL,
                themeID: inputThemeRef.current.getValue(),
            });
        }
    };

    useEffect(() => {
        themes.load({ filter: { themeType: THEME_TYPE.DESIGN } });
    }, []);

    return (
        <Modal
            ref={modalRef}
            id={isEditing ? "edit-design" : "add-design"}
            title={isEditing ? t("edit-design") : t("add-design")}
            className={`p-10 w-1/3`}
            loading={!themes?.ready && !themes?.error}
            error={themes?.error}
            onKeyPress={{ Enter: save }}
            footer={
                <div className="flex justify-end space-x-2">
                    <Button id="cancel" design="white" className="font-bold" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button id="add" design="blue" onClick={save}>
                        {t("save")}
                    </Button>
                </div>
            }
        >
            <div className="flex flex-col space-y-4">
                <div className="flex flex-col space-y-1">
                    <label className="font-bold">{t("name")}*</label>
                    <div>{t("design-name-instructions")}</div>
                    <TextInput ref={inputNameRef} id="design-name" value={nameValue} required={true} />
                </div>
                <div className="flex flex-col space-y-1">
                    <label className="font-bold">{t("appearance")}*</label>
                    <div>{t("default appearance instructions")}</div>
                    <Select
                        id="design-theme"
                        ref={inputThemeRef}
                        required={true}
                        options={themes?.data?.length ? themes?.data.map((t) => ({ label: t.name, value: t.id })) : []}
                        allowUnselect={false}
                        value={themeValue}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default DesignForm;
