import { useState } from "react";
import { GET_TICKER_TAPES } from "./queries/tickerTapes";
import {
    ADD_TICKER_TAPE,
    DELETE_TICKER_TAPES,
    DUPLICATE_TICKER_TAPE,
    SET_TICKER_TAPE_AS_AVAILABLE,
    SET_TICKER_TAPE_AS_UNAVAILABLE,
    UPDATE_TICKER_TAPE,
} from "./mutations/tickertapes";
import { useLazyQuery, useMutation } from "react-apollo";

export const useListTickerTapes = () => {
    const [tickerTapes, setTickerTapes] = useState([]);

    const [listTickerTapes, { loading, error }] = useLazyQuery(GET_TICKER_TAPES, {
        nextFetchPolicy: "cache-first",
        onCompleted: (data) => {
            setTickerTapes(data.tickerTapes.results);
        },
    });

    return {
        getTickerTapes: listTickerTapes,
        loading: loading,
        error: error,
        tickerTapes: tickerTapes,
    };
};

export const useAddTickerTape = (props) => {
    const [executeMutation] = useMutation(ADD_TICKER_TAPE, {
        onCompleted({ createTickerTape }) {
            props.onCompleted({ id: createTickerTape?.id });
        },
    });

    return {
        addTickerTape: executeMutation,
    };
};

export const useDeleteTickerTapes = (props) => {
    const [executeMutation] = useMutation(DELETE_TICKER_TAPES, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        deleteTickerTapes: executeMutation,
    };
};

export const useUpdateTickerTapes = (props) => {
    const [executeMutation] = useMutation(UPDATE_TICKER_TAPE, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        updateTickerTape: executeMutation,
    };
};

export const useSetAvailabilityTickerTape = (props) => {
    const [setAsUnavailable] = useMutation(SET_TICKER_TAPE_AS_UNAVAILABLE, {
        onCompleted() {
            props.onCompleted();
        },
    });

    const [setAsAvailable] = useMutation(SET_TICKER_TAPE_AS_AVAILABLE, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        setAsAvailable,
        setAsUnavailable,
    };
};

export const useDuplicateTickerTape = (props) => {
    const [executeMutation, { loading }] = useMutation(DUPLICATE_TICKER_TAPE, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        duplicateTickerTape: executeMutation,
        loading,
    };
};
