import React, { useState, useEffect, useLayoutEffect } from "react";
import classNames from "classnames";

//Components
import UseSectionHeader from "../../useSectionHeader";
import { Editor } from "react-draft-wysiwyg";

//Utils
import { useTranslation } from "react-i18next";
import { useAuth } from "hooks/Session/auth";
import { capitalizeFirst } from "../../../hooks/Utils/Utils";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import UseButton from "../../useButton";
import { getSelectionCustomInlineStyle, toggleCustomInlineStyle } from "draftjs-utils";
import draftToHtml from "draftjs-to-html";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import uuid from "react-uuid";
import htmlToDraft from "html-to-draftjs";
import UseCheckBox from "../../Inputs/useCheckBox";
import UpdatePrivacyAndConditions from "../../../hooks/GraphqlCalls/Settings/UpdatePrivacyAndConditions";
import { getProjectLangs } from "../../../hooks/Utils/SalesUtils";

import Icon from "components/Icon";
import Button from "components/Button";
import Checkbox from "components/Inputs/Checkbox";
import sanitizeHtml from "sanitize-html";

const PolicyAndConditions = () => {
    const { t } = useTranslation();
    const { languages: projectLangs, isCorporate, isMonoProject } = useAuth();
    const sectionName = `edit-policy-and-conditions`;

    const readOnly = !(isMonoProject || isCorporate);

    const [currentCard, setCurrentCard] = useState(null);
    const [langsArray, setLangsArray] = useState(null);
    const [langTagSelected, setLangTagSelected] = useState(null);
    const [langDefault, setLangDefault] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [currentHtml, setCurrentHtml] = useState();
    const [accessConditions, setAccessConditions] = useState(null);
    const [privacyPolicy, setPrivacyPolicy] = useState(null);
    const [askMarketing, setAskMarketing] = useState(null);
    const [selectMutation, setSelectMutation] = useState(null);

    const CARDS = [
        {
            title: "terms & conditions",
            editable: true,
            id: `${ids[0]}-${"terms-conditions"}`,
            useEditor: true,
            key: "terms-conditions",
        },
        {
            title: "privacy-policy-capitalize",
            editable: true,
            id: `${ids[1]}-${"privacy-policy"}`,
            useEditor: true,
            key: "privacy-policy",
        },
        {
            title: "commercial-policy-capitalize",
            editable: true,
            id: `${ids[2]}-${"commercial-policy"}`,
            key: "commercial-policy",
        },
    ];

    const GET_DATA_CONDITIONS = gql`
        query {
            conditions {
                accessConditions
                askMarketing
                privacyPolicy
            }
        }
    `;

    const [executeQuery, { data: dataConditions, refetch: refetchDataConditions }] = useLazyQuery(GET_DATA_CONDITIONS, {
        onError(err) {
            console.log(err);
        },
    });

    useEffect(() => {
        executeQuery();
        //eslint-disable-next-line
    }, []);

    useLayoutEffect(() => {
        if (currentCard && (currentCard === CARDS[0].id || currentCard === CARDS[1].id)) {
            setLangTagSelected("es");
        }
    }, [currentCard]);

    useEffect(() => {
        if (
            dataConditions &&
            dataConditions.conditions &&
            dataConditions.conditions.accessConditions &&
            dataConditions.conditions.privacyPolicy &&
            (dataConditions.conditions.askMarketing === false || dataConditions.conditions.askMarketing === true)
        ) {
            setAskMarketing(dataConditions.conditions.askMarketing);
            setAccessConditions(JSON.parse(dataConditions.conditions.accessConditions));
            setPrivacyPolicy(JSON.parse(dataConditions.conditions.privacyPolicy));
        }
        //eslint-disable-next-line
    }, [dataConditions]);

    useEffect(() => {
        if (currentCard === CARDS[0].id && accessConditions && accessConditions[langTagSelected]) {
            const newState = getStateFromHtml(accessConditions[langTagSelected]);
            if (newState) {
                setEditorState(newState);
            }
        } else if (currentCard === CARDS[0].id) {
            setEditorState(EditorState.createEmpty());
        }

        if (currentCard === CARDS[0].id) {
            renderedIds("terms-conditions");
        } else if (currentCard === CARDS[1].id) {
            renderedIds("privacy-policy");
        }

        //eslint-disable-next-line
    }, [accessConditions, currentCard, langTagSelected]);

    useEffect(() => {
        if (currentCard === CARDS[1].id && privacyPolicy && privacyPolicy[langTagSelected]) {
            const newState = getStateFromHtml(privacyPolicy[langTagSelected]);
            if (newState) {
                setEditorState(newState);
            }
        } else if (currentCard === CARDS[1].id) {
            setEditorState(EditorState.createEmpty());
        }
        //eslint-disable-next-line
    }, [privacyPolicy, currentCard, langTagSelected]);

    useEffect(() => {
        if (projectLangs) {
            const { langDefault, allLangs } = getProjectLangs(projectLangs, t);
            setLangsArray(allLangs);
            setLangDefault(langDefault);
        }
        //eslint-disable-next-line
    }, [projectLangs]);

    useEffect(() => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setCurrentHtml(html);
        //eslint-disable-next-line
    }, [editorState]);

    const langTag = (data, idx, card) => {
        return (
            <div
                key={data.languageRef + idx}
                className={` cursor-pointer border-b-2 bg-white px-8 py-4 text-base font-bold ${
                    data.languageRef === langTagSelected ? "border-zafiro-600" : "border-gray-400"
                } `}
                id={`${data.languageRef}-button-${card.key}`}
                onClick={() => {
                    setLangTagSelected(data.languageRef);
                }}
            >
                {data.name}
            </div>
        );
    };

    const increaseFontSize = () => {
        const currentSize =
            parseInt(
                (getSelectionCustomInlineStyle(editorState, ["FONTSIZE"]).FONTSIZE + "").replace("fontsize-", "")
            ) || 14;
        const newState = toggleCustomInlineStyle(editorState, "fontSize", currentSize + 4);
        setEditorState(newState);
    };

    const decreaseFontSize = () => {
        const currentSize =
            parseInt(
                (getSelectionCustomInlineStyle(editorState, ["FONTSIZE"]).FONTSIZE + "").replace("fontsize-", "")
            ) || 14;
        if (currentSize <= 10) {
            return;
        }
        const newState = toggleCustomInlineStyle(editorState, "fontSize", currentSize - 4);
        setEditorState(newState);
    };

    const getStateFromHtml = (html) => {
        const contentBlock = htmlToDraft(html);
        const contentState = contentBlock ? ContentState.createFromBlockArray(contentBlock.contentBlocks) : null;
        return contentState ? EditorState.createWithContent(contentState) : EditorState.createEmpty();
    };

    const title = readOnly ? "policy-and-conditions" : "policy-and-conditions-management";
    const breadcrumbs = [
        {
            name: "general-settings",
            translate: true,
            route: "/general-settings",
        },
        {
            name: title,
            translate: true,
            route: "/general-settings/policy-and-conditions-management",
        },
    ];

    return (
        <>
            <UseSectionHeader title={title} customBreadCrumbs={breadcrumbs} />
            {CARDS.map((card, idx) => {
                let cardContent = null;

                if (currentCard === card.id && card.useEditor) {
                    cardContent = (
                        <div
                            className={classNames({
                                "w-full px-10 pb-8": true,
                                "mt-16": !readOnly,
                            })}
                        >
                            <div className="flex items-center justify-between pb-6">
                                <div className="flex w-10/12 flex-wrap">
                                    {langsArray?.length
                                        ? langsArray
                                              ?.filter(
                                                  (lang) =>
                                                      !readOnly || (readOnly && accessConditions?.[lang.languageRef])
                                              )
                                              ?.map((lang, idx) => {
                                                  return langTag(lang, idx, card);
                                              })
                                        : null}
                                </div>
                                {readOnly ? null : (
                                    <div
                                        className="text-zafiro-600 cursor-pointer font-bold"
                                        id={`${card.key}-button-use-template`}
                                        onClick={() => {
                                            setEditorState(
                                                getStateFromHtml(
                                                    card.title === "terms & conditions"
                                                        ? termsAndConditionsTemplate
                                                        : privacyPolicyTemplate
                                                )
                                            );
                                        }}
                                    >
                                        {capitalizeFirst(t("use-template"))}
                                    </div>
                                )}
                            </div>
                            <div className="h-auto">
                                {readOnly ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: langTagSelected
                                                ? sanitizeHtml(accessConditions?.[langTagSelected])
                                                : null,
                                        }}
                                    />
                                ) : (
                                    <Editor
                                        editorState={editorState}
                                        onEditorStateChange={setEditorState}
                                        wrapperClassName={`editor-policy-and-conditions-wrapper ${card.key}-editor-wrapper `}
                                        editorClassName={`editor-policy-and-conditions ${card.key}-editor`}
                                        toolbarClassName={`${card.key}-editor-toolbar`}
                                        toolbarCustomButtons={[
                                            <div
                                                className="increase-size icon icon-formatting-fontize-big flex cursor-pointer items-center justify-center text-3xl text-gray-800"
                                                style={{ minWidth: "25px", height: "20px" }}
                                                key={"increase-button"}
                                                id={`${card.key}-increase-button`}
                                                onClick={() => {
                                                    increaseFontSize();
                                                }}
                                            />,
                                            <div
                                                className="decrease-size icon icon-formatting-fontsize-small flex cursor-pointer items-center justify-center text-3xl text-gray-800"
                                                style={{ minWidth: "25px", height: "20px" }}
                                                id={`${card.key}-decrease-button`}
                                                key={"decrese-button"}
                                                onClick={() => {
                                                    decreaseFontSize();
                                                }}
                                            ></div>,
                                        ]}
                                        toolbar={toolbarEditor}
                                    />
                                )}
                                {readOnly ? null : (
                                    <div className="mt-10 flex w-full justify-end ">
                                        <div
                                            className="mr-2"
                                            onClick={() => {
                                                setCurrentCard(null);
                                            }}
                                        >
                                            <UseButton
                                                buttonName={"cancel"}
                                                buttonColor="btn-white"
                                                id={`${sectionName}-cancel`}
                                            />
                                        </div>
                                        <div
                                            onClick={() => {
                                                if (card.id === CARDS[0].id) {
                                                    setSelectMutation(UPDATE_ACCESS_CONDITIONS);
                                                } else if (card.id === CARDS[1].id) {
                                                    setSelectMutation(UPDATE_PRIVACY_POLICY);
                                                }
                                            }}
                                        >
                                            <UseButton
                                                buttonName={"save"}
                                                buttonColor="btn-blue"
                                                id={`${sectionName}-save`}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                } else if (currentCard === card.id) {
                    cardContent = (
                        <div className="mt-6 bg-white px-10 pb-8">
                            <div className="text-lg font-bold">{capitalizeFirst(t("commercial-communications"))}.</div>
                            <div className="mt-2 flex items-center justify-start text-lg">
                                {readOnly ? (
                                    <Checkbox
                                        id={UPDATE_COMMERCIAL_POLICY}
                                        checked={askMarketing}
                                        readOnly={readOnly}
                                        label={t("request-customer-to-receive-commercial-informations")}
                                    />
                                ) : (
                                    <UseCheckBox
                                        checked={askMarketing}
                                        disabled={readOnly}
                                        name={t("request-customer-to-receive-commercial-informations")}
                                        id={UPDATE_COMMERCIAL_POLICY}
                                    />
                                )}
                            </div>
                            {readOnly ? null : (
                                <div className="mt-10 flex w-full justify-end ">
                                    <div
                                        className="mr-2"
                                        onClick={() => {
                                            setCurrentCard(null);
                                        }}
                                    >
                                        <UseButton
                                            buttonName={"cancel"}
                                            buttonColor="btn-white"
                                            id={`${sectionName}-cancel`}
                                        />
                                    </div>
                                    <div
                                        onClick={() => {
                                            setSelectMutation(UPDATE_COMMERCIAL_POLICY);
                                        }}
                                    >
                                        <UseButton
                                            buttonName={"save"}
                                            buttonColor="btn-blue"
                                            id={`${sectionName}-save`}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                }

                return (
                    <div className="rounded mb-6 bg-white" key={card.title}>
                        <div className="flex w-full items-center justify-center py-5 text-center text-xl font-bold text-gray-900">
                            <div className="w-11/12">{t(card.title)}</div>
                            {card.editable && (readOnly || card.id !== currentCard) ? (
                                <Button
                                    id={`${card.key}-edit-button`}
                                    design="text-link"
                                    className="text-gray-800"
                                    onClick={() => {
                                        if (card.id !== currentCard) {
                                            setLangTagSelected(langDefault);
                                            setCurrentCard(card.id);
                                        } else {
                                            setLangTagSelected(null);
                                            setCurrentCard(null);
                                        }
                                    }}
                                >
                                    <Icon
                                        type={readOnly ? (card.id !== currentCard ? "chevron" : "chevron-up") : "edit"}
                                        size="2xl"
                                    />
                                </Button>
                            ) : null}
                        </div>
                        {cardContent}
                    </div>
                );
            })}

            {readOnly ? null : (
                <UpdatePrivacyAndConditions
                    setSelectMutation={setSelectMutation}
                    selectMutation={selectMutation}
                    refetchDataConditions={refetchDataConditions}
                    accessConditions={accessConditions}
                    currentHtml={currentHtml}
                    langTagSelected={langTagSelected}
                    askMarketing={askMarketing}
                    privacyPolicy={privacyPolicy}
                />
            )}
        </>
    );
};

export default PolicyAndConditions;

export const UPDATE_ACCESS_CONDITIONS = "update-access-conditions";
export const UPDATE_PRIVACY_POLICY = "update-privacy-policy";
export const UPDATE_COMMERCIAL_POLICY = "update-commercial-policy";

const renderedIds = (key) => {
    const toolbar = document.querySelector(`.${key}-editor-toolbar`);
    const editor = document.querySelector(`.${key}-editor`);

    if (toolbar && editor) {
        const rdwBold = document.querySelector(".rdw-bold-button");
        const rdwItalic = document.querySelector(".rdw-italic-button");
        const rdwUnderline = document.querySelector(".rdw-underline-button");
        const rdwJustify = document.querySelector(".rdw-justify-button");
        const rdwLeft = document.querySelector(".rdw-left-button");
        const rdwRight = document.querySelector(".rdw-right-button");
        const rdwOrdered = document.querySelector(".rdw-ordered-button");
        const rdwUnordered = document.querySelector(".rdw-unordered-button");

        // Asignando IDs
        if (editor) {
            editor.id = `${key}-editor`;
        }
        if (rdwBold) {
            rdwBold.id = `${key}-bold-button`;
        }
        if (rdwItalic) {
            rdwItalic.id = `${key}-italic-button`;
        }
        if (rdwUnderline) {
            rdwUnderline.id = `${key}-underline-button`;
        }
        if (rdwJustify) {
            rdwJustify.id = `${key}-justify-button`;
        }
        if (rdwLeft) {
            rdwLeft.id = `${key}-left-button`;
        }
        if (rdwRight) {
            rdwRight.id = `${key}-right-button`;
        }
        if (rdwOrdered) {
            rdwOrdered.id = `${key}-ordered-button`;
        }
        if (rdwUnordered) {
            rdwUnordered.id = `${key}-unordered-button`;
        }
    }
};

const toolbarEditor = {
    options: ["inline", "textAlign", "list"],
    inline: {
        options: ["bold", "italic", "underline"],
        bold: { icon: "", className: "icon icon-bold text-3xl text-gray-800 rdw-bold-button " },
        italic: { icon: "", className: "icon icon-italic text-3xl text-gray-800 rdw-italic-button" },
        underline: { icon: "", className: "icon-underline text-3xl text-gray-800 rdw-underline-button" },
    },
    textAlign: {
        options: ["justify", "left", "right"],
        justify: {
            icon: "",
            className: "icon icon-alignJustify text-3xl text-gray-800 rdw-justify-button",
        },
        left: { icon: "", className: "icon icon-alignLeft text-3xl text-gray-800 rdw-left-button" },
        right: { icon: "", className: "icon-alignRight text-3xl text-gray-800 rdw-right-button" },
    },
    list: {
        options: ["ordered", "unordered"],
        unordered: {
            icon: "",
            className: "icon icon-unordered-list text-3xl rdw-unordered-button text-gray-800",
        },
        ordered: {
            icon: "",
            className: "icon icon-ordered-list text-3xl rdw-ordered-button text-gray-800",
        },
    },
};

const ids = [uuid(), uuid(), uuid()];

const termsAndConditionsTemplate = `
<p>CONDICIONES GENERALES DE USO DE LA RED WIFI Y DE LA APLICACION MOVIL. TRATAMIENTO DE DATOS</p><p></p>
<p>WIFI</p>
<p></p>
<p><strong>RGPD_COMPANY_NAME </strong>facilitará al usuario provisto de equipo (portátil o dispositivo electrónico) con tecnología WIFI, un servicio de acceso inalámbrico a internet.</p>
<p></p>
<p>El usuario facilitará a través de una aplicación de gestión de usuarios, el código pin, cuenta de email, red social o de usuario y contraseña de acceso al servicio. Es responsabilidad del usuario conservar los códigos para el uso del servicio. Los usuarios serán previamente informados de los precios o gratuidad del servicio de acceso a internet.</p>
<p></p>
<p><strong>RGPD_COMPANY_NAME </strong>no asume responsabilidad alguna del contenido, funcionalidad, utilidad, fiabilidad, exactitud, ni de los contenidos incluidos en las páginas web a las que se conecte el usuario.</p>
<p></p>
<p><strong>RGPD_COMPANY_NAME </strong>se esforzará por crear un alto nivel de seguridad, constante, fiable y permanentemente, sin retrasos o interrupciones, pero no se responsabiliza por ningún daño y/o perjuicio y/o beneficios dejados de obtener por el usuario o cualquier otro tercero que se vea perjudicado a consecuencia de los mismos así como tampoco por cualquier circunstancia de fuerza mayor, caso fortuito, fallo o error de las líneas de comunicación, o de la prestación defectuosa o fallo de la red internet.</p>
<p></p>
<p><strong>RGPD_COMPANY_NAME </strong>no será responsable de ningún daño o perjuicio ocasionado a ningún usuario o a terceros, a todo hardware, software y/o programas informáticos pertenecientes al usuario y/o terceros, así como de la pérdida, alteración y/o daños, totales y/o parciales sobre información contenida en soportes magnéticos y otros pertenecientes al usuario y/o terceros, así como por la introducción de virus informáticos o de variaciones o alteraciones no deseadas sobre toda información, documentos, ficheros, bases de datos, hardware y/o software pertenecientes al usuario y/o terceros.</p>
<p></p>
<p>El usuario es responsable de la veracidad y exactitud de la información y datos personales facilitados a <strong>RGPD_COMPANY_NAME </strong>en el momento de contratar los servicios, <strong>RGPD_COMPANY_NAME </strong>no será responsable en el supuesto de falsedad en la información y datos personales provistos por el usuario.</p>
<p></p>
<p>Con el fin de garantizar el buen funcionamiento del servicio al conjunto de los usuarios, las aplicaciones peer-to-peer, es decir aquellas usadas para compartir ficheros de forma masiva, no gozarán de prioridad en la red, pudiendo las mismas no funcionar o sufrir interrupciones.</p>
<p></p>
<p>APLICACION MOVIL</p>
<p></p>
<p><strong>RGPD_COMPANY_NAME </strong>facilitará al usuario una plataforma de acceso a servicios de información del hotel.</p>
<p></p>
<p>El usuario puede haber sido previamente validado por el servidio Wifi. También puede ser identificado a través de un portal en cuyo caso tendrá acceso a servicios adicionales directamente relacionados con su estancia.</p>
<p></p>
<p><strong>RGPD_COMPANY_NAME </strong>se esforzará por crear un alto nivel de seguridad, constante, fiable y permanentemente, sin retrasos o interrupciones, pero no se responsabiliza por ningún daño y/o perjuicio y/o beneficios dejados de obtener por el usuario o cualquier otro tercero que se vea perjudicado a consecuencia de los mismos así como tampoco por cualquier circunstancia de fuerza mayor, caso fortuito, fallo o error de las líneas de comunicación, o de la prestación defectuosa o fallo de la red internet.</p>
<p></p>
<p>El usuario es responsable de la veracidad y exactitud de la información y datos personales facilitados a <strong>RGPD_COMPANY_NAME </strong>en el momento de acceder al servicio, <strong>RGPD_COMPANY_NAME </strong>no será responsable en el supuesto de falsedad en la información y datos personales provistos por el usuario.</p>
<p></p>
<p></p>
<p><strong>RGPD_COMPANY_NAME</strong></p>
<p></p>
<p><strong>RGPD_ADDRESS</strong></p>
<p></p>
<p><strong>RGPD_MANAGER</strong></p>
<p></p>
<p><strong>RGPD_OPERADOR</strong></p>
<p></p>
<p><strong>RGPD_PHONE</strong></p>
<p></p>
<p><strong>RGPD_EMAIL</strong></p>
`;

const privacyPolicyTemplate = `
<p style="text-align:start;">POLÍTICA DE PRIVACIDAD</p><p></p>
<p>A los efectos de lo que dispone el artículo 13 y 14 del Régimen General de Protección de Datos del 26 de Mayo de 2016, por el que se regula el derecho de información en la recogida de datos le informamos que, los datos personales facilitados para contratar los servicios que le serán prestados por <strong>RGPD_COMPANY_NAME, RGPD_ADDRESS</strong>, quedarán incorporados y serán tratados en una base de datos de <strong>RGPD_COMPANY_NAME</strong>. Los datos incorporados serán por norma general: Número de habitación ocupada o servicio utilizado durante el uso de la red, MAC del dispositivo a través del cual accede a la red, Tipo de dispositivo: Pc, móvil o tablet, Tipo de sistema operativo, Idioma original del dispositivo, Idioma seleccionado para utilizar la red, Tipo de navegador utilizado y Fecha y hora de inicio y fin de sesión. En caso de registro y previa autorización expresa por parte del usuario, también se almacenará el correo electrónico y de forma opcional otros datos como Nombre, edad, etc.</p>
<p></p>
<p>Con el fin de poder prestar, ofrecer y dar trámite a los servicios por el usuario contratados. Así mismo, si ha aceptado el envío de información, el usuario autoriza el tratamiento y comunicación de sus datos, para poder recibir información y publicidad sobre productos, servicios y ofertas de <strong>RGPD_COMPANY_NAME</strong>. Dichos datos quedarán registrados en el servidor de Entertainment Solutions S.L. empresa que ofrece el servicio de software de hotspot o plataforma de gestión <strong>ZAFIRO</strong>, la cual no comercializa ni comunica a terceros su contenido y gestiona el tratamiento de datos conforme a la legislación vigente. El registro de estos datos no son procesados por <strong>ZAFIRO</strong> más allá de la creación de estadísticas internas o copias de seguridad en sus servidores.</p>
<p></p>
<p>El usuario podrá, en relación a los referidos tratamientos y con las limitaciones que legalmente puedan establecerse, ejercer sus derechos de acceso, rectificación, cancelación y oposición, ante <strong>RGPD_COMPANY_NAME</strong>, a través de las siguientes direcciones de correo electrónico <strong>RGPD_EMAIL</strong></p>
<p></p>
<p>Para ejercer los derechos ARCO anteriormente indicados, respecto de los datos almacenados puede remitirnos un correo electrónico especificando: nombre y apellidos, número de D.N.I., solicitud que realiza, número de teléfono, domicilio completo, justificación que fundamenta la petición que formula en su caso. Conforme al Art. 12 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, los prestadores de servicios de alojamientos de datos y los proveedores de acceso a redes de telecomunicaciones deberán retener los datos de conexión y tráfico generados durante la prestación del servicio por un período de 12 meses. Los anteriores deben facilitarlos a los Jueces, Tribunales o al Ministerio Fiscal, en el marco de una investigación criminal, o para la salvaguardia de la seguridad pública y la defensa nacional si éstos los requirieran. La obligación de retención de datos de conexión y tráfico se realiza sin conculcar el derecho al secreto de las comunicaciones.</p>
<p></p>
<p>El usuario tiene el derecho a presentar una reclamación a través de <strong>RGPD_PHONE</strong> o <strong>RGPD_EMAIL </strong>o directamente por escrito a nuestro equipo de atención al cliente.</p>
<p></p>
<p>El usuario está obligado a facilitar información sobre sus datos personales, en caso de que la autoridad lo quiera, dado que supone un requisito legal en la lucha contra los delitos en las redes de telecomunicaciones.</p>
<p></p>
<p></p>
<p><strong>COMPANY_NAME</strong></p>
<p></p>
<p><strong>ADDRESS</strong></p>
<p></p>
<p><strong>MANAGER</strong></p>
<p></p>
<p><strong>OPERADOR</strong></p>
<p></p>
<p><strong>PHONE</strong></p>
<p></p>
<p><strong>EMAIL</strong></p>
`;
