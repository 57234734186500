import React, { useContext } from "react";
import classNames from "classnames";

import { GlobalContext } from "contexts/Global";

import Dropdown from "components/Dropdown";
import ProjectName from "components/ProjectName";
import Icon from "components/Icon";

const ProjectsList = ({ projects }) => {
    const { highlightComponent } = useContext(GlobalContext);

    if (!projects?.length) {
        return null;
    }

    return (
        <ul
            className={classNames({
                "px-2 max-h-30 overflow-auto": true,
                "highlight-component": highlightComponent,
            })}
        >
            {projects.map((project) => {
                const id = project?.ref || `${project?.type}-${project?.id}`;
                const name = project?.name || <Icon type="warning" text="Unknown" />;
                const hasChainModule = project?.hasChainModule;
                const groupProjects = project?.projects;
                const isGroup = project?.type === "BRAND" || project?.type === "CHAIN" || groupProjects?.length;

                const displayName = (name, hasChainModule) => (
                    <ProjectName name={name} hasChainModule={hasChainModule} />
                );

                return (
                    <li
                        key={id}
                        className={classNames({
                            "py-3": true,
                            "border-t border-gray-200": projects?.length > 1,
                        })}
                    >
                        {isGroup ? (
                            <Dropdown
                                id="properties"
                                autoClose={false}
                                arrowPosition="left"
                                handler={<strong>{name}</strong>}
                                designClass={{
                                    dropdown: "justify-start space-x-2",
                                }}
                            >
                                <div className="mt-2 pl-6 border-t border-gray-200">
                                    {groupProjects?.length
                                        ? groupProjects.map((p) => {
                                              return (
                                                  <div key={p?.id} className="py-3 border-b border-gray-200">
                                                      {displayName(p?.name, p?.hasChainModule)}
                                                  </div>
                                              );
                                          })
                                        : null}
                                </div>
                            </Dropdown>
                        ) : (
                            displayName(name, hasChainModule)
                        )}
                    </li>
                );
            })}
        </ul>
    );
};
ProjectsList.displayName = "ProjectsList";

export default ProjectsList;
