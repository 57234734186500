import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { toast } from "react-toastify";
import UseSectionHeader from "components/useSectionHeader";
import SettingsCard from "components/SettingsCard";
import { useDesign, useUpdateDesign } from "hooks/Data/useDesigns";
import { useAuth } from "hooks/Session/auth";

import TextInput from "components/TextInput";
import Button from "components/Button";
import Icon from "components/Icon";
import Select from "components/Select";
import Table from "components/ZafiroTable";
import { useModal } from "components/Modal";
import useThemes from "hooks/Data/useThemes";
import Loading from "components/Loading";
import { THEME_TYPE } from "constants/design";
import ProjectName from "components/ProjectName";
import MakeDefault from "sections/design/modals/makeDefault";
import ManageProjects from "sections/design/modals/manageProjects";

const EditDesign = ({ id }) => {
    const { t } = useTranslation();

    const design = useDesign(id, {
        onError: () => toast.error(t("mutation-error")),
    });

    useEffect(() => {
        if (id) {
            design.load();
        }
    }, [id]);

    return (
        <>
            <UseSectionHeader title={design?.data?.name} navToPreviousPage={true} />
            {!design?.ready ? (
                <Loading />
            ) : (
                <div className="grid grid-cols-2 gap-8 mb-5">
                    <NameCard design={design?.data} />
                    <ThemeCard design={design?.data} />
                    <DesignProjects design={design?.data} />
                </div>
            )}
        </>
    );
};

const NameCard = ({ design }) => {
    const { t } = useTranslation();
    const inputRef = useRef(null);

    const [editing, setEditing] = useState(false);

    const update = useUpdateDesign(design?.id, {
        onSuccess: () => {
            toast.success(t("operation-successful"));
            design.name = inputRef.current.getValue();
            setEditing(false);
        },
        onError: () => toast.error(t("mutation-error")),
    });

    const toggleEditing = () => setEditing(!editing);

    const save = () => {
        if (!inputRef.current) {
            return;
        }
        update.post({
            name: inputRef.current.getValue(),
            themeID: design.themeID, // because is required
        });
    };

    return (
        <SettingsCard
            title={t("name")}
            description={
                editing ? (
                    t("Write the name of the design")
                ) : (
                    <div
                        className={classNames({
                            "py-3": true,
                            "font-bold": !!design?.name,
                        })}
                    >
                        {design?.name || <Icon type="warning" text={t("unknown")} />}
                    </div>
                )
            }
            edit={!editing ? { onClick: toggleEditing } : null}
        >
            {editing ? (
                <>
                    <TextInput ref={inputRef} value={design?.name} />
                    <div className="flex justify-end gap-4 mt-6">
                        <Button id="cancel" className="font-bold" design="white" onClick={toggleEditing}>
                            {t("cancel")}
                        </Button>
                        <Button id="save" design="blue" onClick={save}>
                            {t("save")}
                        </Button>
                    </div>
                </>
            ) : null}
        </SettingsCard>
    );
};

const ThemeCard = ({ design }) => {
    const { t } = useTranslation();
    const inputRef = useRef(null);

    const themes = useThemes();

    const [editing, setEditing] = useState(false);

    const update = useUpdateDesign(design?.id, {
        onSuccess: () => {
            toast.success(t("operation-successful"));
            design.themeID = inputRef.current.getValue();
            setEditing(false);
        },
        onError: () => toast.error(t("mutation-error")),
    });

    const themeName = themes?.data?.length ? themes?.data.find((t) => t?.id === design?.themeID)?.name : null;

    const toggleEditing = () => setEditing(!editing);

    const save = () => {
        if (!inputRef.current) {
            return;
        }
        update.post({
            themeID: inputRef.current.getValue(),
            name: design.name, // because is required
        });
    };

    useEffect(() => {
        themes.load({ filter: { themeType: THEME_TYPE.DESIGN } });
    }, []);

    return (
        <SettingsCard
            title={t("default appearance")}
            loading={!themes?.ready && !themes?.error}
            error={themes?.error}
            description={
                editing ? (
                    t("default appearance instructions")
                ) : (
                    <div
                        className={classNames({
                            "py-3": true,
                            "font-bold": !!themeName,
                        })}
                    >
                        {themeName || (
                            <Icon type="warning" text={design?.themeID ? `ID ${design.themeID}` : t("unknown")} />
                        )}
                    </div>
                )
            }
            edit={!editing ? { onClick: toggleEditing } : null}
        >
            {editing ? (
                <>
                    <Select
                        ref={inputRef}
                        id="defaultAppearance"
                        options={themes?.data?.length ? themes?.data.map((t) => ({ label: t.name, value: t.id })) : []}
                        allowUnselect={false}
                        value={design?.themeID}
                    />
                    <div className="flex justify-end gap-4 mt-6">
                        <Button id="cancel" className="font-bold" design="white" onClick={toggleEditing}>
                            {t("cancel")}
                        </Button>
                        <Button id="save" design="blue" onClick={save}>
                            {t("save")}
                        </Button>
                    </div>
                </>
            ) : null}
        </SettingsCard>
    );
};

const DesignProjects = ({ design }) => {
    const { t } = useTranslation();

    const { open } = useModal();
    const { allProjects } = useAuth();

    // This condition is to check if current user has access to all projects
    const manageAll = !allProjects?.some((p) => p.disabled);

    // Don't allow to manage the property or the design if some of them would be left without design applied
    const isEditable = design?.projectsInUse?.length ? !design.projectsInUse.some((p) => p?.hasDefaultDesign) : true;

    const batchActions = [
        {
            id: "make-default",
            label: t("make-default"),
            value: "make-default",
        },
    ];

    const getProjectActions = (project) => [
        {
            id: "make-default",
            label: t("make-default"),
            onClick: () => open(<MakeDefault design={design} project={project} />),
        },
    ];

    const cols = isEditable ? ["properties", "status"] : ["properties", "status", "default"];

    const rows = design?.projectsInUse?.length
        ? design?.projectsInUse.map((project) => {
              const hasChainModule = project?.hasChainModule;
              const isAssigned = project?.designID === design?.id;
              const name = project?.projectName;
              const id = project?.projectID;
              const ref = project?.projectRef;
              const assignText = isAssigned ? t("applied") : t("not-applied");
              return {
                  ...project,
                  isAssigned,
                  properties: (
                      <ProjectName
                          name={name || <Icon type="warning" text={t("unknown")} />}
                          hasChainModule={hasChainModule}
                      />
                  ),
                  status: <span className={isAssigned ? "text-green-100" : null}>{assignText}</span>,
                  default: project?.hasDefaultDesign ? (
                      <div className="text-gray-600">{t("default-design")}</div>
                  ) : null,
                  rowConfig: {
                      actions: isEditable ? getProjectActions({ id, ref, name }) : null,
                  },
              };
          })
        : [];

    return (
        <SettingsCard
            className="bg-white col-span-2 "
            title={t("properties")}
            description={t("Manage the properties that will have access to the design in order to apply it")}
        >
            <div className="border-t border-gray-300 pt-5">
                <Table
                    id="manage-properties"
                    paginate={"lazy"}
                    cols={cols}
                    topRightCorner={
                        isEditable ? (
                            <div className="flex gap-4 items-end h-full">
                                <Button
                                    id="make-all-default"
                                    design="white"
                                    disabled={!manageAll || !rows?.length}
                                    className="font-semibold text-gray-700"
                                    onClick={() => {
                                        open(<MakeDefault design={design} applyAll={true} />);
                                    }}
                                >
                                    {t("make-all-default")}
                                </Button>
                                <Button
                                    id="manage-properties"
                                    design="blue"
                                    onClick={() => open(<ManageProjects design={design} />)}
                                >
                                    {t("Manage properties")}
                                </Button>
                            </div>
                        ) : null
                    }
                    search={true}
                    showCount={true}
                    header={{
                        properties: { title: t("properties"), sortable: true, width: "50%" },
                        status: { title: t("status"), sortable: true, width: "25%" },
                        actions: { title: "" },
                    }}
                    rows={rows}
                    filters={
                        isEditable
                            ? [
                                  {
                                      id: "status",
                                      title: t("status"),
                                      allowUnselect: false,
                                      options: [
                                          { id: "all", label: `-- ${t("all")} --`, value: "" },
                                          { id: "not-applied", label: t("not-applied"), value: "not-applied" },
                                          { id: "applied", label: t("applied"), value: "applied" },
                                      ],
                                      onFilter: (value, row) => {
                                          if (value === "not-applied") {
                                              return !row?.isAssigned ? row : null;
                                          } else if (value === "applied") {
                                              return row?.isAssigned ? row : null;
                                          }
                                          return row;
                                      },
                                  },
                              ]
                            : null
                    }
                    batchActions={
                        isEditable
                            ? {
                                  options: batchActions,
                                  onChange: (action, rows) => {
                                      if (action === "make-default") {
                                          open(
                                              <MakeDefault
                                                  design={design}
                                                  projectRefs={rows?.map((r) => r?.projectRef)}
                                              />
                                          );
                                      }
                                  },
                              }
                            : null
                    }
                />
            </div>
        </SettingsCard>
    );
};

export default EditDesign;
