import { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "react-apollo";
import {
    GET_ASSET,
    GET_FILTERS,
    GET_FILTER_DESTINATION,
    GET_SIGNAGES,
    GET_VIDEO,
    GENERATE_QUERY_GET_DATA_CONTENTS,
    GET_LIBRARY_CONTENTS_AND_DESIGNS_LOCAL_ASSIGNED,
    GET_CONTENT_TREE,
    GET_SCREEN,
    GET_COMMON_ZONE_TVS,
    GET_GROUP_COMMON_ZONE_TVS,
    GENERATE_QUERY_LIBRARY_VIDEOS_INFO,
} from "./queries/signage";
import {
    ADD_SEQUENCE,
    DELETE_SEQUENCES,
    SET_AS_AVAILABLE,
    SET_AS_UNAVAILABLE,
    SET_AS_DEFAULT,
    UPDATE_SEQUENCE,
    UPDATE_SCHEDULE_SEQUENCE,
    DELETE_SIGNAGE_CONTENTS,
    UPDATE_SIGNAGE_CONTENT_DURATION,
    UPDATE_SIGNAGE_CONTENT_POSITION,
    UPDATA_SIGNAGE_CONTENT,
    GENERATE_CREATE_SIGNAGE_CONTENT_MUTATION,
    DUPLICATE_SIGNAGE,
} from "./mutations/signage";
import { useTranslation } from "react-i18next";
import { Session } from "hooks/Utils/Session";
import { formatSecondsToHMS } from "../utils";

export const UseListSignages = () => {
    const [signages, setSignages] = useState([]);

    const [listSignages, { loading, error }] = useLazyQuery(GET_SIGNAGES, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            const localAssignedDesigns = data?.designsLocalAssigned?.results ?? [];
            const globalDesigns = data?.designs?.results ?? [];
            const signages = data?.signages?.results ?? [];
            let designIdAssigned = null;
            if (localAssignedDesigns.length > 0) {
                designIdAssigned = localAssignedDesigns[0]?.id;
            } else if (globalDesigns.length > 0) {
                const corporateDesignAssigned = globalDesigns.find((design) => design.isAssignedFromCorporate);
                if (corporateDesignAssigned) {
                    designIdAssigned = corporateDesignAssigned.id;
                }
            }

            const updatedSignages = signages.map((signage) => ({
                ...signage,
                designIdAssigned,
            }));

            setSignages(updatedSignages);
        },

        onError: (error) => {},
    });

    return {
        getSignages: listSignages,
        loading: loading,
        error: error,
        signages: signages,
    };
};

export const useAddSignage = (props) => {
    const [executeMutation] = useMutation(ADD_SEQUENCE, {
        onCompleted() {
            props.onCompleted();
        },
        onError(err) {
            console.log(err);
        },
    });

    return {
        addSignage: executeMutation,
    };
};

export const useUpdateSignage = (props) => {
    const [executeMutation] = useMutation(UPDATE_SEQUENCE, {
        onCompleted() {
            if (props.onCompleted) {
                props.onCompleted();
            }
        },
        onError(err) {
            if (props.onError) {
                props.onError(err);
            }
        },
    });

    return {
        updateSignage: executeMutation,
    };
};

export const useDeleteSignages = (props) => {
    const [executeMutation] = useMutation(DELETE_SEQUENCES, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        deleteSignages: executeMutation,
    };
};

export const useDuplicateSignage = (props) => {
    const [executeMutation, { loading }] = useMutation(DUPLICATE_SIGNAGE, {
        onCompleted() {
            props.onCompleted();
        },
        onError(err) {
            console.log(err);
        },
    });

    return {
        duplicateSignage: executeMutation,
        loadingDuplicateSignage: loading,
    };
};

export const useAvailabilitySignage = (props) => {
    const [setAsAvailable] = useMutation(SET_AS_AVAILABLE, {
        onCompleted() {
            props.onCompleted();
        },
        onError(err) {
            console.log(err);
        },
    });
    const [setAsUnavailable] = useMutation(SET_AS_UNAVAILABLE, {
        onCompleted() {
            props.onCompleted();
        },
        onError(err) {
            console.log(err);
        },
    });

    return {
        setAsAvailable,
        setAsUnavailable,
    };
};

export const useMakeAsDefaultSequence = (props) => {
    const [executeMutation] = useMutation(SET_AS_DEFAULT, {
        onCompleted() {
            props.onCompleted();
        },
        onError(err) {
            console.log(err);
        },
    });
    return {
        setAsDefault: executeMutation,
    };
};

export const useScheduleSignage = (props) => {
    const [executeMutation] = useMutation(UPDATE_SCHEDULE_SEQUENCE, {
        onCompleted() {
            props.onCompleted();
        },
        onError(err) {
            console.log(err);
        },
    });

    return {
        updateScheduleSignage: executeMutation,
    };
};

export const useListFilters = (props) => {
    const [commonZones, setCommonZones] = useState([]);
    const [groupCommonZones, setGroupCommonZones] = useState([]);
    const [tvLocations, setTvLocations] = useState([]);

    const [listFilters, { loading, error }] = useLazyQuery(GET_FILTERS, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setCommonZones(data.rooms.results);
            setGroupCommonZones(data.roomGroups.results);
            setTvLocations(data.roomtvs.results);
            if (props?.onCompleted) {
                props.onCompleted();
            }
        },
        onError: (error) => {},
    });

    function getFilters() {
        listFilters({ variables: { type: "COMMONZONE" } });
    }

    return {
        getFilters,
        loading: loading,
        error: error,
        filters: { commonZones, tvLocations, groupCommonZones },
    };
};

export const useGetFiltersDestinations = () => {
    const [commonZones, setCommonZones] = useState([]);
    const [tvLocations, setTvLocations] = useState([]);

    const [getFilterDestinations, { loading, error }] = useLazyQuery(GET_FILTER_DESTINATION, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data) {
                setCommonZones(
                    data?.filterDestination?.commonZoneReasons?.map(({ roomId, roomName }) => ({
                        id: roomId,
                        name: roomName,
                    }))
                );
                setTvLocations(
                    data?.filterDestination?.roomTVReasons?.map(({ roomTvId, roomTvName, roomName }) => ({
                        id: roomTvId,
                        name: `${roomName} - ${roomTvName}`,
                    }))
                );
            }
        },
    });

    return {
        getFilterDestinations,
        loading,
        error: error,
        destinations: { commonZones, tvLocations },
    };
};

export const useGetDetailContent = (props) => {
    const CONTENT_TYPES_QUERIES = {
        ASSET: GET_ASSET,
        VIDEO: GET_VIDEO,
        SCREEN: GET_SCREEN,
    };
    const QUERY = CONTENT_TYPES_QUERIES[props.type];

    const [contentData, setContentData] = useState();

    const [getDetailContent, { loading, error }] = useLazyQuery(QUERY, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setContentData(data?.content?.response || data?.content?.results?.[0]);
        },
        onError: (error) => {},
    });

    return {
        getDetailContent,
        data: contentData,
        loading: loading,
        error: error,
    };
};

export const UseGetDataContents = (items, dessingIdAssigned, contentDefaultLanguage) => {
    const { t } = useTranslation();
    const [contentsInfo, setContentsInfo] = useState([]);
    const [totalDuration, setTotalDuration] = useState(0);
    const [getLibraryContentInfo, { loading, error, data }] = useLazyQuery(
        GENERATE_QUERY_GET_DATA_CONTENTS(items, dessingIdAssigned),
        {
            fetchPolicy: "network-only",
            errorPolicy: "all",
            onError: (error) => {
                console.error("Error fetching library content info:", error);
            },
        }
    );

    useEffect(() => {
        if (data) {
            let totalDuration = 0;
            const sortedItems = [...items].sort((a, b) => a.pos - b.pos);
            const processedData = sortedItems.map((item) => {
                const isScreen = item.type === "SCREEN";
                const isVideo = item.type === "VIDEO";
                const alias = isScreen ? `screenItem${item.ref}` : `libraryItem${item.id}`;
                const contentData = isScreen ? data[alias]?.results?.[0] ?? {} : data[alias]?.response ?? {};
                const defaultLanguage = contentData.defaultLanguage || "multilanguage";
                const hasLanguage =
                    contentData?.languages?.some((lang) => lang.language === contentDefaultLanguage) ||
                    contentData?.defaultLanguage === "";
                const isUnlinked = Object.keys(contentData).length === 0 && isScreen;
                const unlinkedSettings = isUnlinked
                    ? { noImageIconClass: "text-red-100", noImageIconSize: 3, noImageIcon: "warning" }
                    : {};
                const baseUrl = `${item.ref}?lang=${contentDefaultLanguage}`;
                const imgSrc = !isScreen && !isVideo ? Session.getDasUrl(`${baseUrl}&w=500&h=500`) : null;
                const thumbnail = isVideo
                    ? Session.getDasUrl(`thumb/${item.ref}-${contentDefaultLanguage}?w=138&h=138`)
                    : Session.getDasUrl(`${baseUrl}&w=500&h=500`);
                const videoSrc = isVideo
                    ? Session.getDasUrl(`stream/${item.ref}-${contentDefaultLanguage}_hd.m3u8`)
                    : null;

                if (!isUnlinked) {
                    totalDuration += item.duration;
                }

                const name = contentData.name ? contentData.name : isUnlinked ? t("unlinked-image") : "";

                return {
                    id: item.id,
                    ref: item.ref,
                    name: name,
                    defaultLanguage,
                    type: item.type,
                    duration: formatSecondsToHMS(item.duration),
                    originalDuration: item.duration,
                    languages: contentData.languages ?? [],
                    showWarning: !hasLanguage && !isScreen,
                    tooltipWarning: t("selected-language-not-found-display-default"),
                    imgSrc: !isVideo ? imgSrc : thumbnail,
                    thumbnail,
                    videoSrc,
                    isUnlinked,
                    ...unlinkedSettings,
                };
            });

            setTotalDuration(totalDuration);
            setContentsInfo(processedData);
        }
    }, [data, items, contentDefaultLanguage]);

    return {
        getLibraryContentInfo,
        loading,
        error,
        contentsInfo,
        totalDurationContents: formatSecondsToHMS(totalDuration),
    };
};

export const UseLibraryVideosInfo = (refs) => {
    const [videosInfo, setVideosInfo] = useState([]);

    const [executeQuery, { loading, error }] = useLazyQuery(GENERATE_QUERY_LIBRARY_VIDEOS_INFO(refs), {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data) {
                console.log(data);

                const videoData = Object.keys(data).map((key) => ({
                    defaultLanguage: data[key]?.response?.defaultLanguage || null,
                    languages: data[key]?.response?.languages || [],
                    ref: data[key]?.response?.ref || [],
                }));

                setVideosInfo(videoData);
            }
        },
        onError: (error) => {
            console.error("Error fetching library video info:", error);
        },
    });

    return {
        getLibraryVideosInfo: executeQuery,
        loading,
        error,
        videosInfo,
    };
};

export const UseListLibraryContentsAndDesigns = () => {
    const [libraryContents, setLibraryContents] = useState([]);
    const [designsLocalAssigned, setDesignsLocalAssigned] = useState([]);
    const [designsAssignedFromCorporate, setDesignsAssignedFromCorporate] = useState([]);

    const [getLibraryContentsAndDesigns, { loading, error }] = useLazyQuery(
        GET_LIBRARY_CONTENTS_AND_DESIGNS_LOCAL_ASSIGNED,
        {
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                setLibraryContents(data?.libraryContents?.response?.results || []);
                setDesignsLocalAssigned(data?.designsLocalAssigned?.results || []);
                setDesignsAssignedFromCorporate(
                    data?.designs?.results.filter((design) => design.isAssignedFromCorporate === true)
                );
            },
            onError: (error) => {
                console.error("Error fetching library contents and designs:", error);
            },
        }
    );

    // console.log(loading)

    return {
        getLibraryContentsAndDesigns,
        loading,
        error,
        libraryContents,
        designsLocalAssigned,
        designsAssignedFromCorporate,
    };
};

export const UseContentTree = () => {
    const [contentTree, setContentTree] = useState(null);

    const [getContentTree, { loading, error }] = useLazyQuery(GET_CONTENT_TREE, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setContentTree(data?.contentTree || null);
        },
        onError: (error) => {
            console.error("Error fetching content tree:", error);
        },
    });

    return {
        getContentTree,
        loading,
        error,
        contentTree,
    };
};

export const useCreateSignageContents = (props = {}) => {
    const [executeMutation, { loading }] = useMutation(GENERATE_CREATE_SIGNAGE_CONTENT_MUTATION([]));

    const createSignageContents = async (contentArray) => {
        const SIGNAGE_CONTENT_MUTATION = GENERATE_CREATE_SIGNAGE_CONTENT_MUTATION(contentArray);
        console.log(SIGNAGE_CONTENT_MUTATION);
        try {
            const result = await executeMutation({ mutation: SIGNAGE_CONTENT_MUTATION });
            props.onCompleted(result);
        } catch (error) {
            props.onError(error);
        }
    };

    return {
        createSignageContents,
        loading,
    };
};
export const useDeleteSignageContents = (props = {}) => {
    const [executeMutation, { loading }] = useMutation(DELETE_SIGNAGE_CONTENTS, {
        onCompleted(data) {
            if (props.onCompleted) {
                props.onCompleted(data);
            }
        },
        onError(error) {
            if (props.onError) {
                props.onError(error);
            }
        },
    });

    return {
        deleteSignageContents: executeMutation,
        loading,
    };
};

export const useUpdateSignageContentPosition = (props = {}) => {
    const [executeMutation, { loading }] = useMutation(UPDATE_SIGNAGE_CONTENT_POSITION, {
        onCompleted(data) {
            props.onCompleted(data);
        },
        onError(error) {
            props.onError(error);
        },
    });

    return {
        updatePosition: executeMutation,
        loading,
    };
};

export const useUpdateSignageContentDuration = (props = {}) => {
    const [executeMutation, { loading }] = useMutation(UPDATE_SIGNAGE_CONTENT_DURATION, {
        onCompleted(data) {
            if (props.onCompleted) {
                props.onCompleted(data);
            }
        },
        onError(error) {
            if (props.onError) {
                props.onError(error);
            }
        },
    });

    return {
        updateDuration: executeMutation,
        loading,
    };
};

export const useUpdateSignageContent = (props = {}) => {
    const [executeMutation, { loading }] = useMutation(UPDATA_SIGNAGE_CONTENT, {
        onCompleted(data) {
            if (props.onCompleted) {
                props.onCompleted(data);
            }
        },
        onError(error) {
            if (props.onError) {
                props.onError(error);
            }
        },
    });

    return {
        updateSignageContent: executeMutation,
        loading,
    };
};

export const useGetCommonZoneTVs = (props = {}) => {
    const [tvs, setTvs] = useState([]);
    const [getCommonZoneTv, { loading, error, data }] = useLazyQuery(GET_COMMON_ZONE_TVS, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setTvs(
                data?.rooms?.results?.[0]?.roomTVs.map((val) => ({
                    ...val,
                    name: `${data?.rooms?.results?.[0]?.name} - ${val.name}`,
                }))
            );
        },
        onError: (error) => {},
    });

    return {
        getCommonZoneTv,
        loading: loading,
        error: error,
        tvs,
    };
};

export const useGetGroupCommonZoneTVs = () => {
    const [tvs, setTvs] = useState([]);
    const [getGroupCommonZoneTv, { loading, error, data }] = useLazyQuery(GET_GROUP_COMMON_ZONE_TVS, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setTvs(
                data?.roomGroups?.results?.reduce((acc, curr) => {
                    acc.push(
                        ...(curr?.rooms?.reduce((roomtvs, current) => {
                            roomtvs.push(
                                ...(current?.roomTVs.map((val) => ({
                                    ...val,
                                    name: `${current.name} - ${val.name}`,
                                })) || [])
                            );

                            return roomtvs;
                        }, []) || [])
                    );
                    return acc;
                }, []) || []
            );
        },
        onError: (error) => {},
    });

    return {
        getGroupCommonZoneTv,
        loading: loading,
        error: error,
        tvs,
    };
};
