import React, { useContext, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Session } from "../../../../hooks/Utils/Session";

import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";
import EmptyWidget from "./WidgetToolbars/CommonOptions/EmptyWidget";

const WidgetCarousel = () => {
    const { deviceType } = useContext(EditorContext);
    const { config, type, id: widgetID } = useContext(WidgetContext);

    const { lang } = useParams();
    const { gridItems } = useSelector((state) => state.design);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const style = config?.style || Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);
    const data = config?.data || Widget.ParseData(widget ? widget.data : null, deviceType);
    const imagesCount = data && data.images ? data.images.length : 0;
    const intervalDuration = data && data.duration > 0 ? data.duration : 30;
    const isAutomatic = !!data?.automatic;
    const initialImage = data?.initialImage || 0;

    const [currentImage, setCurrentImage] = useState(initialImage);

    const current = data?.images?.[currentImage];
    const src = current?.libraryRef ? getFromDAS(current.libraryRef, lang) : current?.externalUrl;
    const bgSize = data
        ? data.adjust === "cover-adjust"
            ? "100% 100%"
            : data.adjust
            ? data.adjust
            : "cover"
        : "cover";

    const timer = useRef(null);

    const stopSlideshow = () => {
        if (timer.current) {
            clearInterval(timer.current);
        }
    };
    const startSlideshow = () => {
        stopSlideshow();
        timer.current = setInterval(() => {
            setCurrentImage((prev) => (prev >= imagesCount - 1 ? 0 : prev + 1));
        }, intervalDuration * 1000);
    };

    useEffect(() => {
        setCurrentImage(initialImage);
        if (isAutomatic) {
            startSlideshow();
        } else {
            stopSlideshow();
        }
    }, [initialImage, isAutomatic]);

    useEffect(() => {
        if (isAutomatic) {
            startSlideshow();
        } else {
            stopSlideshow();
        }
        return stopSlideshow;
    }, [intervalDuration]);

    useEffect(() => {
        const elm = document.querySelector(`#widget_zone_${widgetID}`);
        if (elm) {
            elm.style.background = "transparent no-repeat center center";
            elm.style.backgroundImage = src ? `url('${src}')` : "";
            elm.style.backgroundSize = bgSize;
            elm.style.borderRadius = style && style.radius ? `${style.radius}rem` : "";
        }
    }, [bgSize, style, src]);

    return <>{imagesCount > 0 ? null : <EmptyWidget radius={style ? style.radius : 0} type={type}></EmptyWidget>}</>;
};

const getFromDAS = (libraryRef, languageRef) => {
    if (libraryRef) {
        return Session.getDasUrl(`${libraryRef}?lang=${languageRef}`);
    }
    return null;
};

export default WidgetCarousel;
